import { defaultGetArticlesResp } from '../../articles/mockGetArticles';
import { defaultGetCreditsResp } from '../../mockData/getCredits';
import {
  defaultGetBooksRawResp,
  defaultGetBooksResp,
} from '../../mockData/getBooks';
import { defaultGetEventsResp } from '../../mockData/getEvents';
import { defaultGetSectionsResp } from '../../mockData/getSections';
import { makeAPIFn, makeTableAPI2 as table } from '../../utils/apiFunctions';
import { defaultGetProductsResp } from '../../mockData/getProducts';
import { defaultGetDeliveryOptionsResp } from '../../mockData/getDeliveryOptions';
import { defaultGetProductFiltersResp } from '../../mockData/getProductFilters';

const book_ = {
  fetchURL: '/api/strapDB/books.php',
  testData: defaultGetBooksResp,
};
const book = ((
  fetchAll = makeAPIFn.fetchAllFn(book_.fetchURL, book_.testData),
) => ({
  fetchAll,
  fetchOne: makeAPIFn.fetchOneFn(book_.fetchURL, fetchAll),
}))();

/** Database accessor objects with tables. */
export default {
  article: table('article', defaultGetArticlesResp),
  book,
  bookRaw: table('book', defaultGetBooksRawResp),
  credit: table('credit', defaultGetCreditsResp),
  section: table('section', defaultGetSectionsResp),
  event: table('event', defaultGetEventsResp),
  product: table('product', defaultGetProductsResp),
  productFilters: table('productFilter', defaultGetProductFiltersResp),
  deliveryOption: table('deliveryOption', defaultGetDeliveryOptionsResp),
};
