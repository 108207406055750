import { FileToUpload } from '@draft-js-plugins/drag-n-drop-upload';
import { FileData } from '@draft-js-plugins/drag-n-drop-upload/lib/handleDroppedFiles';

type SucessFunction = (uploadedFiles: Array<FileToUpload>) => void;
type FailFunction = (file: FileToUpload) => void;
type ProgressFunction = (percent: number, file: FileToUpload) => void;

export function uploadImageFromDnD(
  data: FileData,
  success: SucessFunction,
  failed: FailFunction,
  progress: ProgressFunction,
) {
  // success([]);
  progress(1, { name: 'haha', src: 'jaskjdhaksjhd' });
  console.log(data, success, failed, progress);
  return '';
  let intervalId = -1;
  let currentProgress = 0;

  intervalId = setInterval(() => {
    if (currentProgress < 100) {
      currentProgress += 10;
      data.files.forEach(imageFile => {
        progress(currentProgress, imageFile);
      });
    }

    if (currentProgress === 100) {
      clearInterval(intervalId);

      // readFiles(data.files).then((filesRead) => {
      //   const mockResult = filesRead.map(f => ({ name: f.name, src: f.src }));
      //   success(mockResult/*, { retainSrc: true }*/);
      // });
    }
  }, 1000);
}
