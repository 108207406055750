import React, { useEffect, useRef, useState } from 'react';
import {
  Badge,
  Icon,
  IconButton,
  TextField,
  useMediaQuery,
} from '@mui/material';
import * as styles from './shopPage.module.scss';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_KEY } from './utils';

type ShopSearchBoxProps = {
  onSubmit: (value: string) => void;
};

export default function ShopSearchBox(props: ShopSearchBoxProps) {
  const [value, setValue] = useState('');
  const [isSmallBarOpen, setIsSmallBarOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    const newVal = searchParams.get(SEARCH_KEY) || '';
    setValue(newVal);
    props.onSubmit(value);
  }, [searchParams]);

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    applySearch();
    setIsSmallBarOpen(false);
  };

  function onClickSearchIcon() {
    setIsSmallBarOpen(!isSmallBarOpen);
  }

  // auto focus the search bar when icon is tapped to reveal it
  useEffect(() => {
    if (isSmallBarOpen) {
      if (inputRef.current == null)
        throw new Error('Null inputRef when small bar is open!');
      inputRef.current.focus();
    }
  }, [isSmallBarOpen]);

  function onBlurSearchBar() {
    if (isSmall) onClickSearchIcon();
    applySearch();
  }

  function applySearch() {
    if (!value) searchParams.delete(SEARCH_KEY);
    else searchParams.set(SEARCH_KEY, value);
    setSearchParams(searchParams);
  }

  const isSmall = useMediaQuery('(max-width: 574px)');

  return (
    <div>
      {isSmall && !isSmallBarOpen ? (
        <IconButton disableTouchRipple={true} onClick={onClickSearchIcon}>
          <Badge badgeContent={value ? '+' : 0} color="primary">
            <Icon fontSize="large">search</Icon>
          </Badge>
        </IconButton>
      ) : (
        <form onSubmit={onFormSubmit}>
          <TextField
            inputRef={inputRef}
            onBlurCapture={onBlurSearchBar}
            className={styles.searchBar}
            variant="outlined"
            label="Search"
            id="search-box"
            value={value}
            size={isSmall ? 'small' : 'medium'}
            onChange={e => setValue(e.target.value)}
            onBlur={onFormSubmit}
          />
        </form>
      )}
    </div>
  );
}
