.ingestForm {
  // display: grid;
  // grid-template-columns: 1fr auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 2em;
}

.formActions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;
}

.fieldWithEdit {
  display: flex;
  gap: 0.5em;
  align-items: center;
  & > :first-child {
    flex-grow: 1;
  }
}
