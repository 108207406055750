import React from 'react';
import BasicButtonWithURLInputAndUpload from './basicButtonWithURLInputAndUpload';

type VideoButtonProps = {
  onSubmit: (videoURL: string) => void;
};

export default function VideoButton(props: VideoButtonProps) {
  return (
    <BasicButtonWithURLInputAndUpload
      onSubmit={props.onSubmit}
      iconName="movie"
      acceptedMimeTypes="video/mp4,audio/mpeg"
    />
  );
}
