import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { submitAdminLogin } from '../api/adminAPI';
import { popAdminLoginLastPath, useIsLoggedInAsAdmin } from './adminUtils';
import './adminLoginPage.sass';

export default function AdminLoginPage() {
  const [failed, setFailed] = useState(false);
  const navigate = useNavigate();
  const adminMode = useIsLoggedInAsAdmin();

  if (adminMode) {
    navigate_Impl();
  }

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const username = formData.get('username')?.toString();
    const password = formData.get('password')?.toString();
    if (typeof username != 'string' || typeof password !== 'string')
      throw new Error('not string username or password');
    const success = await submitAdminLogin({ username, password });
    if (success) {
      navigate_Impl();
    } else {
      setFailed(true);
    }
  }

  function navigate_Impl() {
    navigate(popAdminLoginLastPath(), { replace: true });
  }

  return (
    <main className="adminLoginPage">
      <Container>
        <h2>Admin Mode: Login</h2>
        {failed && <span>Invalid login. Please try again.</span>}
        <form onSubmit={e => onSubmit(e)}>
          <TextField
            id="username"
            name="username"
            label="Username"
            variant="standard"
            fullWidth
          />
          <TextField
            id="password"
            type="password"
            name="password"
            label="Password"
            variant="standard"
            fullWidth
          />
          <Button type="submit" variant="contained" disableRipple>
            Submit
          </Button>
        </form>
      </Container>
    </main>
  );
}
