.bookCarouselScrollWrap::-webkit-scrollbar {
  width: 20px;
  /* width of the entire scrollbar */
}

.bookCarouselScrollWrap::-webkit-scrollbar-track {
  background: #eee4;
  /* color of the tracking area */
  border-radius: 7px;
}

.bookCarouselScrollWrap::-webkit-scrollbar-thumb {
  background-color: #6666;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  //border: 3px solid rgb(5, 5, 5); /* creates padding around scroll thumb */
}

body::-webkit-scrollbar-track {
  background: rgb(239, 238, 236);
}

.bookCarousel {
  position: relative;
  width: 100%;
  margin: 10px;
  margin-bottom: 15px;
}

.bookCarouselScrollWrap {
  width: 100%;
  overflow-x: scroll;
  background: #d0d2d044;
  padding: 5em 4em 2em;
  border-radius: 50px 50px 10px 10px;
}

.bookCarouselEdgeShadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 50px 50px 10px 10px;
  $d: #0003;
  $c: #0001;
  $e: #0000;
  background: linear-gradient(90deg, $d, $c 3%, $e 10%, $e 90%, $c 97%, $d);
}

.bookCarouselTrack {
  display: flex;
  flex-direction: row;
  gap: 2em;
  border-radius: 10px;
}

.bookCarouselItem {
  --eventAccentHue: 190;
  text-decoration: none! important;
  text-align: center;
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 3px;
  width: 200px;
  height: 250px;
  border: 5px solid hsl(var(--eventAccentHue), 40%, 75%);
  border-radius: 10px;
  background-color: hsl(var(--eventAccentHue), 40%, 95%);
  box-shadow: 1px 2px 3px 1px #0004, 1px 2px 5px 3px #0002;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  &.seeMore {
    justify-content: space-around;
    background-color: #ccc;
    border-color: #444;
  }
}

.bookCarouselItemEndSpacer {
  padding-right: 4em;
}

.bookCarouselItem:hover {
  cursor: pointer;
  background-color: beige;
}

.bookCarouselTitle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0.75rem 0 0.75rem 0;
  color: #171717;
  border-bottom: 4px solid #8882;
}

.libBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 110%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.sectionInner {
  margin: 10px;
  text-align: center;
  width: 150px;
  @media screen and (max-width: 350px) {
    width: 120px;
  }
  aspect-ratio: 4 / 3;
  font-size: 20px;
  background-color: #53b943;
  color: #eee;
  border: none;
  border-radius: 6px;
}

.sectionInner:hover {
  cursor: pointer;
  background-color: #85b67d;
}

.bookTitle {
  padding: 5px;
  font-weight: bolder;
  font-size: 20px;
  text-decoration: none! important;
}

.bookAuthor {
  padding: 5px;
  font-weight: normal;
  font-size: 19px;
}
