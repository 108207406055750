import { composeDecorators } from '@draft-js-plugins/editor';
import createDndPlugin from '@draft-js-plugins/drag-n-drop';
import createDndUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import createFocusPlugin from '@draft-js-plugins/focus';
import createImagePlugin from '@draft-js-plugins/image';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createVideoPlugin from '@draft-js-plugins/video';
import { uploadImageFromDnD } from './uploadImage';

export const dndPlugin = createDndPlugin();
export const focusPlugin = createFocusPlugin();
const decorator = composeDecorators(focusPlugin.decorator, dndPlugin.decorator);
export const imagePlugin = createImagePlugin({ decorator });
export const dndUploadPlugin = createDndUploadPlugin({
  handleUpload: uploadImageFromDnD,
  addImage: (editorState, placeholderStr) =>
    imagePlugin.addImage(editorState, placeholderStr?.toString() ?? '', {}),
});
export const linkifyPlugin = createLinkifyPlugin();
export const toolbarPlugin = createToolbarPlugin();
export const videoPlugin = createVideoPlugin({
  theme: {
    iframe: 'articleVideo--iframe',
    video: 'articleVideo--video',
    invalidVideoSrc: 'articleVideo--invalidVideoSrc',
    iframeContainer: 'articleVideo--iframeContainer',
  },
});
