import React, { useState } from 'react';
import * as styles from './mainPage.module.scss';
import { condenseText } from '../../utils/stringUtils';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

type BookCarouselItemBookProps = {
  book: BookJson;
};

export function BookCarouselItemBook(props: BookCarouselItemBookProps) {
  let [randomHue] = useState(Math.floor(Math.random() * 360));
  return (
    <div
      className={styles.bookCarouselItem}
      style={{ ['--eventAccentHue' as string]: randomHue }}
    >
      <div className={styles.bookTitle}>
        <Link
          to={`/library?search=${props.book.title}`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          {condenseText(props.book.title, 56)}
        </Link>
      </div>
      <div className={styles.bookAuthor}>
        <Link
          to={`/library?search=${props.book.author?.name}`}
          style={{ textDecoration: 'none',color: 'black' }}
        >
          {condenseText(props.book.author?.name || '', 40)}
        </Link>
      </div>
    </div>
  );
}

type BookCarouselItemSectionProps = {
  section: SectionJson;
};

export function BookCarouselItemSection(props: BookCarouselItemSectionProps) {
  let [randomHue] = useState(Math.floor(Math.random() * 360));
  return (
    <>
      <Link to={`/library?section=${props.section.name}`} style={{ textDecoration: 'none' , color: 'black'}}>
        <div
          className={classNames(styles.bookCarouselItem, styles.seeMore)}
          style={{
            ['--eventAccentHue' as string]: randomHue,
            textDecoration: 'none',
          }}
        >
          <div className={styles.bookTitle} >
            View Rest of Section
          </div>
        </div>
      </Link>
      <div className={styles.bookCarouselItemEndSpacer}></div>
    </>
  );
}
