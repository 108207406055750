import { CardElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import axios from 'axios';
import { defaultGetOrdersResp } from '../../mockData/getOrders';
import { isDevMode } from '../../utils/functions';
import { Cart } from '../cartAPI';

/**
 * @param cart param for cart data, and compute price on server
 * @param customerCollects whether to exclude delivery costs
 * @returns client secret
 */
export async function createPaymentIntent(
  cart: Readonly<Cart>,
  customerCollects: boolean,
) {
  if (isDevMode()) {
    return '';
  }
  const params = new FormData();
  params.append('cart', JSON.stringify(cart));
  params.append('customerCollects', customerCollects.toString());
  const result = await axios.post('/api/shopAPI/paymentIntent.php', params);
  return (result.data as GetPaymentIntentResp).clientSecret;
}

export async function submitPayment(submission: SubmitPaymentSubmission) {
  if (isDevMode()) {
    return true;
  }

  const params = new FormData();
  params.append('clientSecret', submission.clientSecret);
  params.append('firstName', submission.firstName);
  params.append('lastName', submission.lastName);
  params.append('email', submission.email);
  params.append('shippingAddr', JSON.stringify(submission.shippingAddr));
  if (submission.billingAddr) {
    params.append('billingAddr', JSON.stringify(submission.billingAddr));
  }
  const result = await axios.post('/api/shopAPI/orderSubmit.php', params);
  return (result.data as SubmitPaymentResp).success;
}

/** returns error message if failed or empty string if ok */
export async function confirmStripePayment(
  elements: StripeElements,
  stripe: Stripe,
  clientSecret: string,
  billingAddr: Address,
) {
  if (isDevMode()) {
    return '';
  }

  const card = elements.getElement(CardElement);
  if (!card) throw new Error('Missing card element');
  const ret = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card,
      billing_details: {
        address: {
          line1: billingAddr.line1,
          line2: billingAddr.line2,
          city: billingAddr.city,
          postal_code: billingAddr.postcode,
        },
      },
    },
    return_url: 'https://wales-armenia.org.uk/shop/stripeReturn',
  });
  if (ret.error) return ret.error.message || 'Payment failed. Please try again';
  return '';
}

export async function getOrders() {
  if (isDevMode()) {
    return defaultGetOrdersResp;
  }
  const result = await axios.get('/api/shopAPI/orders.php');
  const data = result.data as GetOrderResp;
  return data;
}
