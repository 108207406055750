import { mockDataOrDefault } from '../utils/functions';

export const defaultGetEventsResp = mockDataOrDefault<EventJson[]>(
  [],
  [
    {
      id: 1,
      title: 'Test Event',
      date: '2027-03-10T12:00:00',
      description: 'asdajshdkjahlskdjahsda\r\nnew paragraph\r\n',
    },
    {
      id: 2,
      title: 'Old Event',
      date: '2014-03-12T15:00:00',
      description:
        'ashaskhjdashasdjhasdha jkdfshdlkjfhsdf\r\nsdf\r\nsdf\r\nkd\r\ngkdf\r\ngkdf\r\nasdkahsldjhalskjdhlaksjhdl kjashldkjashlk jhsdlkjh lsjkdfh lkjdfghl kjdfghl djkfghlk jhdfglkj dfg\r\n\r\n\r\nkjdfhksjdhfslkdhf lkjdfhg ljkdfhgl jkhlkjash flkjsdhlfkjh slkjdhflakjh lkjasdh fl',
    },
    {
      id: 3,
      title: 'event 2',
      date: '2018-03-12T15:00:00',
      description: 'haha'
    },
    {
      id: 4,
      title: 'event 272',
      date: '2023-03-12T15:00:00',
      description: 'haha'
    },
    {
      id: 5,
      title: 'event 273',
      date: '2033-01-12T15:00:00',
      description: 'haha'
    },
    {
      id: 6,
      title: 'event 274',
      date: '2033-02-12T15:00:00',
      description: 'haha'
    },
    {
      id: 7,
      title: 'event 99',
      date: '2050-03-12T15:00:00',
      description: 'haha'
    },
  ],
);
