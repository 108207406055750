import React, { useEffect, useRef, useState } from 'react';
import strapDB from '../api/strapDB';
import { ArticleType } from '../utils/enums';
import { scrollToElement } from '../utils/functions';
import { useScrollEffect } from '../utils/miscComponents';
import ArticleViewer from './articleViewer';
import * as styles from './articleGroupViewer.module.scss';

type ArticleGroupViewerProps = {
  articleType: ArticleType;
  onYearsRetrieved: (value: number[]) => void;
  onScrolledToYear: (value: number) => void;
  jumpToYear: number | undefined;
  onArticleDeleted?: () => void;
};

export default function ArticleGroupViewer(props: ArticleGroupViewerProps) {
  const [articles, setArticles] = useState<ArticleJson[]>([]);
  const skippedFirstYearJump = useRef(false);
  const [scrolledYear, setScrolledYear] = useState(0);
  const [forceReloadCounter, setForceReloadCounter] = useState(0);

  useEffect(() => {
    strapDB.article.fetchAll('useCache').then(allArticles => {
      const filteredArticles = allArticles.filter(
        article => article.articleType == props.articleType,
      );
      setArticles(filteredArticles);

      props.onYearsRetrieved([
        ...new Set(
          filteredArticles.map(x => new Date(x.publishedOn).getFullYear()),
        ),
      ]);
    });
  }, [props.articleType, forceReloadCounter]);

  // jump to year
  useEffect(() => {
    if (!props.jumpToYear) return;
    if (!skippedFirstYearJump.current) {
      skippedFirstYearJump.current = true;
      return;
    }
    if (props.jumpToYear == scrolledYear) return;
    const el = document.querySelector(`[data-year='${props.jumpToYear}']`);
    if (!el) return;
    scrollToElement(el, 50);
  }, [props.jumpToYear]);

  // detect scroll change
  useScrollEffect(() => {
    const yearElements = document.querySelectorAll(`[data-year]`);
    let yearEl: ReturnType<typeof yearElements.item> | undefined;
    yearElements.forEach(el => {
      if (!yearEl && el.getBoundingClientRect().top > window.innerHeight / -2) {
        yearEl = el;
      }
    });
    if (!yearEl) return;
    const newScrolledYear = +yearEl.getAttribute('data-year')!;
    setScrolledYear(newScrolledYear);
    props.onScrolledToYear(newScrolledYear);
  });

  function onArticleDeleted() {
    if (props.onArticleDeleted) props.onArticleDeleted();
    // force a reload
    setForceReloadCounter(forceReloadCounter + 1);
  }

  return (
    <div className={styles.articleGroup}>
      {articles.map(i => (
        <ArticleViewer
          key={i.id}
          articleData={i}
          onDeleted={onArticleDeleted}
          showPublishedOn
        />
      ))}
    </div>
  );
}
