import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import strapDB from '../api/strapDB';
import { splitPastUpcomingEvents } from '../utils/functions';

type AddPastEventDialogProps = {
  handle: AddPastEventDialogHandle;
  onContinue: (eventID: number) => void;
};

export default function AddPastEventDialog(props: AddPastEventDialogProps) {
  function onCloseDialog() {
    props.handle.setIsOpen(false);
  }

  function onClickContinue() {
    if (props.handle.eventID == undefined)
      throw new Error('invalid state undefined continue');
    props.onContinue(props.handle.eventID);
    props.handle.setIsOpen(false);
  }

  function onClickCancel() {
    props.handle.setIsOpen(false);
  }

  const canSave = props.handle.eventID != undefined;

  const dateFormat = Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format;

  return (
    <Dialog fullWidth onClose={onCloseDialog} open={props.handle.isOpen}>
      <DialogContent>
        <h4 className="mb-4">Add Past Event</h4>
        <FormControl fullWidth>
          <InputLabel>Select an event</InputLabel>
          <Select
            variant="standard"
            value={props.handle.eventID ?? ''}
            onChange={e => props.handle.setEventID(+e.target.value)}
          >
            {props.handle.events.map(x => (
              <MenuItem key={x.id} value={x.id}>
                {x.title} ({dateFormat(new Date(x.date))})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClickContinue}
          disabled={!canSave}
          variant="contained"
          disableRipple
        >
          Continue
        </Button>
        <Button onClick={onClickCancel} disableRipple>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type AddPastEventDialogHandle = ReturnType<typeof useAddPastEventDialogHandle>;

/**
 * Separate dialog state from component
 *
 * This is so to be able to put data in another component, and handle
 * opening the dialog and resetting data in a unified handle.
 */
export function useAddPastEventDialogHandle() {
  const [eventID, setEventID] = useState<number | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [events, setEvents] = useState<EventJson[]>([]);

  // Class expression to be able to take inferred types from calls
  // to useState above, avoiding duplication by defining the class
  // separately.
  return new (class AddPastEventDialogHandle_Impl {
    // state vars
    eventID = eventID;
    setEventID = setEventID;
    isOpen = isOpen;
    setIsOpen = setIsOpen;
    events = events;
    setEvents = setEvents;

    // helper functions
    /** Open the dialog, with resettled data */
    open() {
      setIsOpen(true);
      setEventID(undefined);
      strapDB.event
        .fetchAll('useCache')
        .then(events => setEvents(splitPastUpcomingEvents(events).past));
    }
  })();
}
