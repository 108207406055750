import React from 'react';
import ReactDOM from 'react-dom';
import { isLoggedInAsAdmin2 } from './api/adminAPI';
import App from './app';

// Reset session when reloading the page to clear cache
// TODO: maybe only clear api fetches?
sessionStorage.clear();
isLoggedInAsAdmin2();

ReactDOM.render(<App />, document.getElementById('app'));
