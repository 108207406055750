import { Icon, IconButton, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useState } from 'react';
import { isLoggedInAsAdmin } from '../api/adminAPI';
import * as styles from './eventsPage.module.scss';

type EventCardProps = {
  event: EventJson;
  onEdit: () => void;
  onDelete: () => void;
};

export default function EventCard(props: EventCardProps) {
  const adminMode = isLoggedInAsAdmin();
  const event = props.event;
  const [randomHue] = useState(Math.floor(Math.random() * 360));

  return (
    <Card
      className={styles.eventCard}
      style={{ ['--eventAccentHue' as string]: randomHue }}
    >
      <CardContent>
        <Stack direction="row" gap={1}>
          {adminMode && (
            <Stack gap={1}>
              <IconButton onClick={props.onEdit} size="small" color="primary">
                <Icon>edit</Icon>
              </IconButton>
              {/* <IconButton onClick={props.onDelete} size="small" color="primary">
                <Icon>delete</Icon>
              </IconButton> */}
            </Stack>
          )}
          <div>
            <h4 className={styles.eventTitle}>{event.title}</h4>
            <p className={styles.eventDate}>
              {Intl.DateTimeFormat('en', {
                dateStyle: 'long',
                timeStyle: 'short',
              }).format(new Date(event.date))}
            </p>
            <p className={styles.eventDescription}>{event.description}</p>
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
}
