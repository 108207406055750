h1{
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookList {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.bookListing {
  display: flex;
  gap: 0.5em;
}

.adminInlineToolStrip {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.infoReadout {
  display: flex;
  flex-direction: column;

}
