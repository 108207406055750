import React from 'react';
import * as styles from './mainPage.module.scss';
import {
  BookCarouselItemBook,
  BookCarouselItemSection,
} from './bookCarouselItem';

type BookCarouselProps = {
  section: SectionJson;
  books: BookJson[];
  // title: string;
};

export default function BookCarousel(props: BookCarouselProps) {
  return (
    <div className={styles.bookCarousel}>
      <h3 className={styles.bookCarouselTitle}>{props.section.name}</h3>
      <div className={styles.bookCarouselEdgeShadow} />
      <div className={styles.bookCarouselScrollWrap}>
        <div className={styles.bookCarouselTrack}>
          {props.books
            .filter(book => book.section.id == props.section.id)
            .filter((_, i) => i < 15)
            .map(book => (
              <BookCarouselItemBook  book={book} key={book.id} />
            ))}
          <BookCarouselItemSection section={props.section} />
        </div>
      </div>
    </div>
  );
}
