import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import BasicPage from '../utils/basicPage';
import { Col, Row } from 'react-bootstrap';
import { Alert, Button, Icon } from '@mui/material';
import * as styles from './productViewerPage.module.scss';
import strapDB from '../api/strapDB';
import { useSnackbar } from '../utils/snackbar';
import { formatCompactCurrency } from '../utils/stringUtils';
import CartWidget from './cartWidget';
import { useIsLoggedInAsAdmin } from '../admin/adminUtils';
import { ViewerPageAdminToolbar } from '../utils/adminComponents';
import { useCart } from '../api/cartAPI';

export default function ProductViewerPage() {
  const { productID } = useParams();
  const [product, setProduct] = useState<ProductJson | undefined>();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const cart = useCart();
  const adminMode = useIsLoggedInAsAdmin();

  useEffect(() => {
    const productIDInt = parseInt(productID ?? '');
    if (isNaN(productIDInt)) {
      setProduct(undefined);
      handleProductFetchError();
      return;
    }
    strapDB.product.fetchOne(productIDInt, 'useCache').then(value => {
      if (value) setProduct(value);
      else handleProductFetchError();
    });
  }, [productID]);

  function handleProductFetchError() {
    snackbar.error(`Invalid article ID: ${productID}`);
    navigate('/shop', { replace: true });
  }

  function onClickBackToAll() {
    navigate('/shop');
  }

  const isInCart = cart.includes(+productID!);

  function onClickCartButton() {
    if (isInCart) {
      navigate('/shop/cart');
    } else {
      cart.addProduct(+productID!);
    }
  }

  if (!product) {
    return <></>;
  }

  const imageCarouselItems = (JSON.parse(product.imageLinks) as string[]).map(
    (src: string) => ({
      original: src,
      originalHeight: 400,
    }),
  );

  return (
    <BasicPage title={`Product | ${product.title}`}>
      <CartWidget variant="fixed" />
      {adminMode && <ViewerPageAdminToolbar id={+productID!} table="product" />}
      <Button
        onClick={onClickBackToAll}
        disableRipple
        startIcon={<Icon>chevron_left</Icon>}
      >
        Back to All products
      </Button>
      <h2 className={styles.title}>{product.title}</h2>
      <Row>
        <Col md={8}>
          <ImageGallery
            additionalClass={styles.carousel}
            items={imageCarouselItems}
            showFullscreenButton={false}
            showThumbnails={false}
            showPlayButton={false}
          />
        </Col>
        <Col md={4}>
          <p className={styles.description}>{product.description}</p>
          <p>
            Price: {formatCompactCurrency(product.price)}
            <br />
            Delivery: {formatCompactCurrency(product.deliveryCost)}
          </p>
          <Button
            disabled={product.isOutOfStock}
            onClick={onClickCartButton}
            disableRipple
            variant="contained"
          >
            {isInCart ? 'View Cart' : 'Add to cart'}
          </Button>
          {product.isOutOfStock && (
            <Alert className={styles.outOfStock} severity="warning">
              Regrettably this item is out of stock
            </Alert>
          )}
        </Col>
      </Row>
    </BasicPage>
  );
}
