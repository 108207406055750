import React from 'react';
import { EditorState } from 'draft-js';
import { Separator } from '@draft-js-plugins/static-toolbar';
import * as buttons from '@draft-js-plugins/buttons';
import { videoPlugin, imagePlugin, toolbarPlugin } from '../draftjsPlugins';
import ImageButton from './imageButton';
import VideoButton from './videoButton';
import * as styles from '../articleContentEditor.module.scss';

type EditorToolbarProps = {
  editorState: EditorState;
  setEditorState: (value: EditorState) => void;
};

export default function EditorToolbar(props: EditorToolbarProps) {
  function onSubmitImageButton(imageURL: string) {
    props.setEditorState(imagePlugin.addImage(props.editorState, imageURL, {}));
  }

  function onSubmitVideoButton(videoURL: string) {
    props.setEditorState(
      videoPlugin.addVideo(props.editorState, { src: videoURL }),
    );
  }

  return (
    <div className={styles.toolbar}>
      <toolbarPlugin.Toolbar>
        {childProps => (
          <div className={styles.toolbarInner}>
            <buttons.BoldButton {...childProps} />
            <buttons.ItalicButton {...childProps} />
            <buttons.UnderlineButton {...childProps} />
            <Separator />
            <buttons.HeadlineThreeButton {...childProps} />
            <buttons.UnorderedListButton {...childProps} />
            <buttons.OrderedListButton {...childProps} />
            <Separator />
            <ImageButton onSubmit={onSubmitImageButton} />
            <VideoButton onSubmit={onSubmitVideoButton} />
          </div>
        )}
      </toolbarPlugin.Toolbar>
    </div>
  );
}
