import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { verifyViewingPageAsAdminOrLogin } from '../admin/adminUtils';
import * as shopAPI from '../api/shopAPI';
import BasicPage from '../utils/basicPage';
import { formatCurrency, formatTimestamp } from '../utils/stringUtils';
import * as styles from './ordersPage.module.scss';

export default function OrdersPage() {
  verifyViewingPageAsAdminOrLogin();
  const [data, setData] = useState<OrderJson[]>([]);
  useEffect(() => void shopAPI.getOrders().then(setData));

  const onClickRefund = (it: OrderJson) => () => {
    alert('refund not implemented yet');
  };

  return (
    <BasicPage title="Orders">
      <div className={styles.itemList}>
        <div className={styles.row}>
          <span>Date</span>
          <span>Email</span>
          <span>Amount</span>
          <span>Fees</span>
          <span>Refunded</span>
          <span>Net</span>
          <span>Invoice</span>
          <span>Refund</span>
        </div>
        {data
          .sort((a, b) => b.date.localeCompare(a.date))
          .map((it, i) => (
            <div className={styles.row} key={i}>
              <span>{formatTimestamp(it.date)}</span>
              <span>{it.email}</span>
              <span>{formatCurrency(it.amount)}</span>
              <span>{formatCurrency(it.fee)}</span>
              <span>{formatCurrency(it.refunded)}</span>
              <span>{formatCurrency(it.amount - it.fee)}</span>
              <div>
                <Button
                  href={`/api/shopAPI/invoice.php?order=${it.clientSecret}`}
                  disableRipple
                  size="small"
                >
                  Invoice
                </Button>
              </div>
              <div>
                <Button onClick={onClickRefund(it)} disableRipple size="small">
                  Refund
                </Button>
              </div>
            </div>
          ))}
      </div>
    </BasicPage>
  );
}
