import { CardActionArea, CardMedia, Icon, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCompactCurrency } from '../utils/stringUtils';
import * as styles from './productCard.module.scss';

type ProductCardProps = {
  product: ProductJson;
};

export default function ProductCard(props: ProductCardProps) {
  //   const event = props.event;
  const isSmall = useMediaQuery('(max-width:575px');
  const navigate = useNavigate();

  function onClick() {
    navigate(`/shop/product/${props.product.id}`);
  }

  const imageLinks = JSON.parse(props.product.imageLinks);
  return (
    <Card>
      <CardActionArea
        disableRipple
        onClick={onClick}
        className={styles.productCardContent}
      >
        <CardMedia
          component="img"
          height={isSmall ? 300 : 240}
          image={imageLinks[0]}
        />
        <span
          className={classNames(styles.priceTag, {
            [styles.outOfStock]: props.product.isOutOfStock,
          })}
        >
          {props.product.isOutOfStock && (
            <Icon color="warning">warning_amber</Icon>
          )}
          <span className={styles.priceTag2}>
            {formatCompactCurrency(props.product.price)}
          </span>
        </span>
        <p className={styles.title}>{props.product.title}</p>
      </CardActionArea>
    </Card>
  );
}
