import { Button, Divider, Stack } from '@mui/material';
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { verifyViewingPageAsAdminOrLogin } from '../admin/adminUtils';
import BasicPage from '../utils/basicPage';
import { toDateString } from '../utils/functions';
import { useSnackbar } from '../utils/snackbar';
import { condenseText } from '../utils/stringUtils';
import ArticleContentEditor from './articleContentEditor';
// import './articleEditorPage.sass';
import * as styles from './articleEditorPage.module.scss';
import strapDB from '../api/strapDB';
import { ArticleType } from '../utils/enums';
import classNames from 'classnames';
// import { ArticleType } from '../utils/enums';

/**
 * Admin only page for creating or updating articles.
 *
 * Uses detail page's styling as a base as the two pages are quite
 * similar.
 */
export default function articleEditorPage() {
  verifyViewingPageAsAdminOrLogin();

  const [isLoaded, setIsLoaded] = useState(false);
  const [headline, setHeadline] = useState('');
  const [byline, setByline] = useState('');

  const location = useLocation();
  const basePath = (() => {
    const match = /(.*)\/(?:new|\d+\/edit)/.exec(location.pathname);
    return match ? match[1]! : '';
  })();
  const defaultArticleType =
    {
      '/about': ArticleType.About,
      '/past-events': ArticleType.PastEvent,
      '/events': ArticleType.PastEvent,
      '/about/communityNews': ArticleType.CommunityNews,
    }[basePath] || ArticleType.About;
  const [articleType, setArticleType] = useState(defaultArticleType);
  const [publishedOn, setPublishedOn] = useState(toDateString(new Date()));
  const [contentEditorState, setContentEditorState] = useState(
    EditorState.createEmpty(),
  );
  const snackbar = useSnackbar();

  const navigate = useNavigate();
  const params = useParams();
  /** If undefined we are creating. Otherwise updating. */
  let articleID = parseInt(params.id || '') || undefined;
  const [searchParams] = useSearchParams();
  const canSave = !!(
    headline &&
    serializeArticleEditor(contentEditorState).brief &&
    publishedOn
  );

  if (!isLoaded) {
    setIsLoaded(true); // Set even if failed to prevent multiple error message.
    if (articleID) {
      loadArticleData(articleID);
    }
  }

  // copying data from event
  /** If defined, start a new article based on the past event. */
  const eventId = parseInt(searchParams.get("eventId") || '') || undefined;
  useEffect(() => {
    if (eventId) {
      strapDB.event.fetchOne(eventId, 'useCache').then(event => {
        if (!event) throw new Error(`Invalid event with id ${eventId}`);
        setHeadline(event.title);
        setPublishedOn(event.date.split("T")[0]!);
        setContentEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(event.description),
          ),
        );
      });
    }
  }, [eventId]);

  async function loadArticleData(articleID: number) {
    const articleData = await strapDB.article.fetchOne(articleID, 'useCache');
    if (!articleData) {
      snackbar.error(`Invalid article ID: ${articleID}`);
      navigate(basePath);
      return;
    }
    setHeadline(articleData.headline);
    setPublishedOn(articleData.publishedOn);
    setByline(articleData.byline || '');
    if ((articleData.articleType as ArticleType) == ArticleType.About) {
    }
    if (articleData.articleType == ArticleType.About) {
    }

    setArticleType(articleData.articleType);
    const contentState = convertFromRaw(JSON.parse(articleData.content));
    setContentEditorState(EditorState.createWithContent(contentState));
  }

  async function onClickSave() {
    const { content, brief } = serializeArticleEditor(contentEditorState);
    const submission = {
      headline,
      publishedOn,
      byline,
      content,
      brief,
      articleType,
    };
    const saveSuccess = await (articleID !== undefined
      ? saveOverExistingArticle({ ...submission, id: articleID })
      : saveAsNewArticle(submission));
    showSaveNotification(saveSuccess, articleID);
  }

  function onClickDiscard() {
    if (
      confirm(
        `Are you sure you want to discard${
          articleID ? ' changes to' : ''
        } the article?`,
      )
    ) {
      navigate(articleID ? linkToViewerPage(articleID) : basePath);
    }
  }

  async function saveAsNewArticle(submission: Omit<ArticleJson, 'id'>) {
    const newArticleID = await strapDB.article.create(submission);
    articleID = newArticleID;
    if (newArticleID) {
      navigate(`${basePath}/${newArticleID}/edit`, { replace: true });
      return true;
    }
    return false;
  }

  async function saveOverExistingArticle(submission: ArticleJson) {
    return await strapDB.article.update(submission);
  }

  function showSaveNotification(saveSuccess: boolean, articleID?: number) {
    if (saveSuccess) {
      if (!articleID) throw new Error('invalid articleID for successful save');
      snackbar.success('Saved article', {
        action: (
          <Button
            className={styles.saveSuccessAction}
            variant="text"
            color="secondary"
            onClick={() => navigate(linkToViewerPage(articleID))}
          >
            View page
          </Button>
        ),
      });
    } else {
      snackbar.error('Failed to save article');
    }
  }

  /** link back to viewer page */
  function linkToViewerPage(articleID: number) {
    return basePath == '/events' ? basePath : `${basePath}/${articleID}`;
  }

  return (
    <BasicPage
      className={classNames(styles.articleEditorPage, styles.articleViewerPage)}
      title={`[${articleID ? 'Editing' : 'Creating'}] ${headline}`}
    >
      <div className={styles.articleHeader}>
        <h2>
          <input
            value={headline}
            onChange={e => setHeadline(e.target.value)}
            placeholder="Headline"
          />
        </h2>
        <p className={styles.publishedOn}>
          <input
            value={publishedOn}
            onChange={e => setPublishedOn(e.target.value)}
            type="date"
          />
        </p>
        <p className={styles.byline}>
          By&nbsp;
          <input
            value={byline}
            onChange={e => setByline(e.target.value)}
            placeholder="Author"
          />
        </p>
        <div className="articleType">
          Article Type:&nbsp;
          <select
            value={articleType}
            onChange={e => setArticleType(+e.target.value)}
          >
            <option value={ArticleType.About}>About</option>
            <option value={ArticleType.PastEvent}>Past Event</option>
            <option value={ArticleType.GenocideInformation}>
              Genocide Information
            </option>
            <option value={ArticleType.CommunityNews}>Community News</option>
            <option value={ArticleType.None}>None</option>
          </select>
        </div>
        <Divider variant="middle" />
      </div>
      <ArticleContentEditor
        editorState={contentEditorState}
        setEditorState={setContentEditorState}
      />
      <Stack className="adminToolbar" direction="row" gap={1}>
        <Button
          onClick={onClickSave}
          disabled={!canSave}
          variant="contained"
          disableRipple
        >
          Save
        </Button>
        <Button onClick={onClickDiscard} variant="text" disableRipple>
          Discard {articleID ? 'changes' : 'article'}
        </Button>
      </Stack>
    </BasicPage>
  );
}

function serializeArticleEditor(editorState: EditorState) {
  const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
  const brief = condenseText(
    editorState
      .getCurrentContent()
      .getPlainText(' ')
      .split(' ')
      .filter(x => x)
      .join(' '),
  );
  return { content, brief };
}
