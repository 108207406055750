import { mockDataOrDefault } from '../utils/functions';

export const defaultGetSectionsResp = mockDataOrDefault<SectionJson[]>(
  [],
  [
    {
      id: 1,
      name: 'Culture',
    },
    {
      id: 2,
      name: 'Genocide',
    },
    {
      id: 3,
      name: 'History',
    },
    {
      id: 4,
      name: 'Biography',
    },
    {
      id: 5,
      name: 'Language',
    },
    {
      id: 6,
      name: 'Religion',
    },
    {
      id: 7,
      name: 'Travel',
    },
    {
      id: 8,
      name: "Children's",
    },
    {
      id: 9,
      name: 'Fiction',
    },
    {
      id: 10,
      name: 'Poems',
    },
    {
      id: 11,
      name: 'Stories',
    },
  ],
);
