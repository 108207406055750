import { useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import {
  isLoggedInAsAdmin,
  isLoggedInAsAdmin2,
  submitAdminLogin,
} from '../api/adminAPI';
import { Location } from 'history';
import { isDevMode } from '../utils/functions';
import { useSessionStorage } from 'usehooks-ts';

type AdminNavigateHooks = {
  navigate: NavigateFunction;
  location: Location;
  adminMode: boolean;
  setAdminMode?: (a: boolean) => void;
};

/**
 * @param hooks If unspecified hooks will be made in this function.
 * It will have to be called in the function component root. If in
 * another function you must specify the hooks separately.
 */
export function verifyViewingPageAsAdminOrLogin(
  hooks?: AdminNavigateHooks,
  replace = true,
) {
  const navigate = hooks?.navigate ?? useNavigate();
  const location = hooks?.location ?? useLocation();
  const adminMode = hooks?.adminMode ?? useIsLoggedInAsAdmin();
  if (isDevMode()) {
    if (hooks?.setAdminMode) {
      submitAdminLogin({ username: 'test', password: '' }).then(
        hooks?.setAdminMode,
      );
    }
    return;
  }
  if (!adminMode) {
    sessionStorage.setItem('adminLoginLastPath', location.pathname);
    const doNavigate = () => navigate('/admin/login', { replace });
    if (hooks) {
      doNavigate();
    } else {
      useEffect(doNavigate, [adminMode]);
    }
  }
}

export function useSwitchToAdminMode() {
  const navigate = useNavigate();
  const location = useLocation();
  const adminMode = useIsLoggedInAsAdmin();
  let [_, setAdminMode] = useSessionStorage('isLoggedInAsAdmin', false);
  return () =>
    verifyViewingPageAsAdminOrLogin(
      { navigate, location, adminMode, setAdminMode },
      false,
    );
}

export function popAdminLoginLastPath() {
  const path = sessionStorage.getItem('adminLoginLastPath');
  sessionStorage.removeItem('adminLoginLastPath');
  return path || '/admin';
}

export function useIsLoggedInAsAdmin() {
  // const [adminMode, setAdminMode] = useState(isLoggedInAsAdmin());
  let [adminMode, setAdminMode] = useSessionStorage('isLoggedInAsAdmin', false);
  adminMode = sessionStorage.getItem('isLoggedInAsAdmin') == 'true';

  const [refetchAdminMode, setRefetchAdminMode] = useState(true);

  if (refetchAdminMode) {
    setRefetchAdminMode(false);
    isLoggedInAsAdmin2().then(setAdminMode);
  }

  return adminMode;
}
