.title {
  margin: 0.2em 0 1em 0;
}

.carousel {
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
}

.description {
  white-space: pre-wrap;
}

.editPrice {
  width: 8ch;
  margin-bottom: 0.2rem;
}

.outOfStock {
  margin-top: 1em;
}
