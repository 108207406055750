// The drop shadow on three sides but not the top so the widget appears
// flush with the navbar effect is created by wrapping the div in
// another div which provides padding on the right, bottom and left but
// not the top and having its overflow hidden. This results in the
// drop shadow of the inner element being cut-off at the top.

.shadowWrap {
  position: sticky;
  z-index: 1000;
  top: 80px;
  margin-top: -54px;
  padding: 0px 20px 20px 20px;
  margin-left: auto;
  width: fit-content;
  overflow: hidden;
  margin-right: -12px;
}

.shadowWrapPushed {
  margin-right: -20px;
}

.shadowWrapFixed {
  position: fixed;
  top: 134px;

  $smIconShrink: 144px;
  left: calc(100vw - 263px + $smIconShrink);
  @media (min-width: 576px) {
    left: calc(50vw + 6px + $smIconShrink);
  }
  @media (min-width: 768px) {
    left: calc(50vw + 96px);
  }
  @media (min-width: 992px) {
    left: calc(50vw + 215px);
  }
  @media (min-width: 1200px) {
    left: calc(50vw + 306px);
  }
}

.mainCard {
  // make the top edge flush with the navbar at the top
  border: 1px solid transparent;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  height: 52px;
}
