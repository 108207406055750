import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import strapDB from '../api/strapDB';
import { useSnackbar } from '../utils/snackbar';

export type BookEditDialogCloseReason = 'renamed' | 'deleted' | 'cancelled';

type SectionEditDialogProps = {
  isOpen: boolean;
  onClose: (reason: BookEditDialogCloseReason, newVal?: SectionJson) => void;
  onChange: (value: SectionJson | null) => void;
  initialValue: SectionJson | null;
};

export function SectionEditDialog(props: SectionEditDialogProps) {
  const [internalValue, setSectionInternal] = useState(props.initialValue);
  const snackbar = useSnackbar();

  if (props.isOpen && props.initialValue && !internalValue) {
    setSectionInternal(props.initialValue);
  }
  const canSave = !!internalValue?.name;

  function onCloseDialog() {
    close_Impl('cancelled');
  }

  function onClickSave() {
    if (!internalValue) throw new Error('null section to save');
    strapDB.section.update(internalValue).then(success => {
      if (success) {
        props.onChange(internalValue);
        snackbar.success('Successfully saved section');
      }
    });
    close_Impl('renamed');
  }

  function onClickCancel() {
    close_Impl('cancelled');
  }

  function onClickDelete() {
    if (confirm('Are you sure you want to delete this section?')) {
      if (!internalValue) throw new Error('null section to delete');
      strapDB.section.delete(internalValue.id).then(success => {
        if (success) {
          props.onChange(null);
          const msg = 'Successfully deleted section';
          snackbar.success(msg);
        } else {
          const msg =
            "Failed to delete section. Please make sure it isn't referenced by any books.";
          snackbar.error(msg);
        }
      });
      props.onChange(null);
      close_Impl('deleted');
    }
  }

  function close_Impl(reason: BookEditDialogCloseReason) {
    setTimeout(() => setSectionInternal(null), 100);
    props.onClose(reason);
  }

  return (
    <Dialog open={props.isOpen} onClose={onCloseDialog} maxWidth="xs" fullWidth>
      <DialogContent>
        <h4 className="mb-3">Edit Section</h4>
        <form onSubmit={e => (e.preventDefault(), onClickSave())}>
          <TextField
            fullWidth
            label="Name"
            variant="standard"
            value={internalValue?.name ?? ''}
            onChange={e =>
              setSectionInternal({
                id: internalValue!.id,
                name: e.target.value,
              })
            }
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClickSave}
          disabled={!canSave}
          variant="contained"
          disableRipple
        >
          Save
        </Button>
        <Button onClick={onClickCancel} disableRipple>
          Cancel
        </Button>
        <Button onClick={onClickDelete} disableRipple>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type CreditEditDialogProps = {
  isOpen: boolean;
  onClose: (reason: BookEditDialogCloseReason, newVal?: CreditJson) => void;
  onChange: (value: CreditJson | null) => void;
  initialValue: CreditJson | null;
};

export function CreditEditDialog(props: CreditEditDialogProps) {
  const [internalValue, setCreditInternal] = useState(props.initialValue);
  const snackbar = useSnackbar();

  if (props.isOpen && props.initialValue && !internalValue) {
    setCreditInternal(props.initialValue);
  }
  const canSave = !!internalValue?.name;

  function onCloseDialog() {
    close_Impl('cancelled');
  }

  function onClickSave() {
    if (!internalValue) throw new Error('null credit to save');
    strapDB.credit.update(internalValue).then(success => {
      if (success) {
        snackbar.success('Successfully saved author');
        props.onChange(internalValue);
      }
    });
    close_Impl('renamed');
  }

  function onClickCancel() {
    close_Impl('cancelled');
  }

  function onClickDelete() {
    if (confirm('Are you sure you want to delete this author?')) {
      if (!internalValue) throw new Error('null credit to delete');
      strapDB.credit.delete(internalValue.id).then(success => {
        if (success) {
          props.onChange(null);
          const msg = 'Successfully deleted author';
          snackbar.success(msg);
        } else {
          const msg =
            "Failed to delete author. Please make sure it isn't referenced by any books.";
          snackbar.error(msg);
        }
      });
      close_Impl('deleted');
    }
  }

  function close_Impl(reason: BookEditDialogCloseReason) {
    setTimeout(() => setCreditInternal(null), 100);
    props.onClose(reason);
  }

  return (
    <Dialog open={props.isOpen} onClose={onCloseDialog} maxWidth="xs" fullWidth>
      <DialogContent>
        <h4 className="mb-3">Edit Author</h4>
        <form onSubmit={e => (e.preventDefault(), onClickSave())}>
          <TextField
            fullWidth
            label="Name"
            variant="standard"
            value={internalValue?.name ?? ''}
            onChange={e =>
              setCreditInternal({
                id: internalValue!.id,
                name: e.target.value,
              })
            }
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClickSave}
          disabled={!canSave}
          variant="contained"
          disableRipple
        >
          Save
        </Button>
        <Button onClick={onClickCancel} disableRipple>
          Cancel
        </Button>
        <Button onClick={onClickDelete} disableRipple>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
