.item {
  display: flex;
  gap: 1em;
}

.itemInfo {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.title {
  flex-grow: 1;
  color: black !important;
  text-decoration: none !important;
}

.quantityRoot {
  margin-bottom: -2px;
}

.actionBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 1em;
  margin-top: 3em;
  &>span {
    margin-right: 1ch;
  }
}
