import { ButtonGroup, Button, Icon, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useIsLoggedInAsAdmin } from '../admin/adminUtils';
import strapDB from '../api/strapDB';
import BasicPage from '../utils/basicPage';
import { shareUrl } from '../utils/functions';
import CartWidget from './cartWidget';
import ProductCard from './productCard';
import * as styles from './shopPage.module.scss';
import ShopSearchBox from './shopSearch';
import ShopSort from './shopSort';
import { ShopSortType } from '../utils/enums';
import ShopFilterDialog from './shopFilterDialog';
import {
  SEARCH_KEY,
  FILTER_KEY,
  ShopItemsView,
  applyProductsItemView,
  SORT_KEY,
  filterIDsToFilterJsons,
} from './utils';

export default function ShopPage() {
  const [products, setProducts] = useState<ProductJson[]>([]);
  const [itemsView, setItemsView] = useState<ShopItemsView>({
    search: '',
    sort: ShopSortType.DateAddedDescending,
    filters: [],
  });
  const isAdmin = useIsLoggedInAsAdmin();

  useEffect(() => refreshList(), [itemsView]);

  function refreshList() {
    strapDB.product
      .fetchAll('useCache')
      .then(products =>
        setProducts(applyProductsItemView(products, itemsView)),
      );
  }

  return (
    <BasicPage title="Shop">
      {isAdmin && <AdminHeader />}
      <Container className={styles.innerRoot}>
        <img src={shareUrl('homePage/hydesign.jpg')} className={styles.logo} />
        <ShopHeader itemsView={itemsView} setItemsView={setItemsView} />
        <ProductGrid products={products} />
      </Container>
    </BasicPage>
  );
}

type ProductGridProps = {
  products: ProductJson[];
};

function ProductGrid(props: ProductGridProps) {
  return (
    <Row>
      {props.products.map((it, i) => (
        <Col className={styles.productCardWrapper} key={i} lg={4} md={6}>
          <ProductCard product={it} />
        </Col>
      ))}
      {!props.products.length && (
        <Col>
          <h2>No Products Found</h2>
          <p>No products matched your search.</p>
          <Link to="/shop">
            <Button
              startIcon={<Icon>clear</Icon>}
              variant="contained"
              disableRipple={true}
            >
              Reset Search
            </Button>
          </Link>
        </Col>
      )}
    </Row>
  );
}

function AdminHeader() {
  const navigate = useNavigate();

  function onClickCreateProduct() {
    navigate('/shop/product/new');
  }

  function onClickViewOrders() {
    navigate('/shop/orders');
  }

  return (
    <ButtonGroup
      className="indexPageAdminPrimaryAction"
      variant="contained"
      size="large"
      disableRipple
      fullWidth
    >
      <Button onClick={onClickCreateProduct} startIcon={<Icon>add</Icon>}>
        Create Product
      </Button>
      <Button onClick={onClickViewOrders} startIcon={<Icon>receipt_long</Icon>}>
        View orders
      </Button>
    </ButtonGroup>
  );
}

type ShopHeaderProps = {
  itemsView: ShopItemsView;
  setItemsView: (value: ShopItemsView) => void;
};

/**
 * search:
 * <user string>
 *
 * sort:
 * A-Z
 * $-$$$
 * $$$-$
 *
 * Filter:
 * [reset]
 * [] mens (20)
 * [] ladies (10)
 * [] unspecified (80)
 *
 * category
 * [reset]
 * [] books (3)
 * [] clothing (3)
 * [] jewelry (4)
 * [] accessories (7)
 * [] bags (40)
 * [] home (3)
 * [] flags (3)
 *
 * material
 * [] silver (3)
 * [] ceramic (2)
 * [] fabric (38)
 * [] gold (2)
 * [] wood (6)
 * [] glass (2)
 * [] silk (1)
 *
 */
function ShopHeader(props: ShopHeaderProps) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  function onSubmitSearch(value: string) {
    props.setItemsView({ ...props.itemsView, search: value });
  }

  function onSelectSortOption(value: ShopSortType) {
    props.setItemsView({ ...props.itemsView, sort: value });
  }

  function onClickFilterIcon() {
    setIsFilterOpen(true);
  }

  function onCloseFilterDialog(enabledFilters: number[]) {
    setIsFilterOpen(false);
    filterIDsToFilterJsons(enabledFilters).then(filters => {
      props.setItemsView({ ...props.itemsView, filters });
    });
  }

  const [searchParams] = useSearchParams();

  useEffect(() => void loadFromSearchParams(), []);
  useEffect(() => {
    // TODO: move to the actual reset button click handler?
    // Reset page state if clear button was pressed.
    if (searchParams.size == 0) loadFromSearchParams();
  }, [searchParams]);
  async function loadFromSearchParams() {
    const filterStrings = searchParams.getAll(FILTER_KEY);
    const filters = await filterIDsToFilterJsons(filterStrings.map(Number));
    const search = searchParams.get(SEARCH_KEY) || '';
    const sort =
      +searchParams.get(SORT_KEY)! || (props.itemsView.sort as ShopSortType);
    props.setItemsView({ filters, search, sort });
  }

  return (
    <>
      <div className={styles.shopHeader}>
        <ShopSearchBox onSubmit={onSubmitSearch} />
        <ShopSort onSelect={onSelectSortOption} />
        <IconButton disableTouchRipple={true} onClick={onClickFilterIcon}>
          <Icon fontSize="large">filter_alt</Icon>
        </IconButton>
      </div>
      <CartWidget variant="sticky" />
      <ShopFilterDialog isOpen={isFilterOpen} onClose={onCloseFilterDialog} />
    </>
  );
}
