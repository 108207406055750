import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';

type CheckoutStepperProps = {
  currentStep: number;
};

const g_stepsNames = ['Cart', 'Shipping', 'Payment'] as const;

/** reusable comp for putting on checkout pages - but not to update dynamically */
export default function CheckoutStepper(props: CheckoutStepperProps) {
  return (
    <Stepper className="mb-4" activeStep={props.currentStep}>
      {g_stepsNames.map((stepName, i) => (
        <Step key={i} completed={i < props.currentStep}>
          <StepLabel>{stepName}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
