import React from 'react';
import { Divider } from '@mui/material';
import * as plugins from './draftjsPlugins';
import Editor from '@draft-js-plugins/editor';
import {
  CompositeDecorator,
  convertFromRaw,
  DraftDecorator,
  EditorState,
} from 'draft-js';
import { isLoggedInAsAdmin } from '../api/adminAPI';
import { ViewerPageAdminToolbar } from '../utils/adminComponents';
import * as styles from './articleViewer.module.scss';

type ArticleViewerProps = {
  articleData: ArticleJson;
  showPublishedOn?: boolean;
  onDeleted?: () => void;
};

export default function ArticleViewer(props: ArticleViewerProps) {
  const contentState = convertFromRaw(JSON.parse(props.articleData.content));
  const decorator = new CompositeDecorator(
    plugins.linkifyPlugin.decorators as DraftDecorator[],
  );
  const contentEditorState = EditorState.createWithContent(
    contentState,
    decorator,
  );
  const year = new Date(props.articleData.publishedOn).getFullYear();

  return (
    <div className={styles.articleViewer} data-year={year}>
      <ArticleHeader
        articleData={props.articleData}
        onDeleted={props.onDeleted}
        showPublishedOn={props.showPublishedOn ?? false}
      />
      <Editor
        readOnly
        editorState={contentEditorState}
        onChange={() => void 0}
        plugins={[
          plugins.dndPlugin,
          plugins.dndUploadPlugin,
          plugins.focusPlugin,
          plugins.linkifyPlugin,
          plugins.toolbarPlugin,
          plugins.imagePlugin,
          plugins.videoPlugin,
        ]}
      />
    </div>
  );
}

function ArticleHeader(props: {
  articleData: ArticleJson;
  onDeleted?: () => void;
  showPublishedOn: boolean;
}) {
  const adminMode = isLoggedInAsAdmin();
  const dateFormatFn = Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format;
  return (
    <div className={styles.articleHeader}>
      <h2>{props.articleData.headline}</h2>
      {adminMode && (
        <ViewerPageAdminToolbar
          id={props.articleData.id}
          table="article"
          onDeleted={props.onDeleted}
        />
      )}
      {props.showPublishedOn &&
      <p className={styles.publishedOn}>
        {dateFormatFn(new Date(props.articleData.publishedOn))}
      </p>
      }
      <p className={styles.byline}>By {props.articleData.byline}</p>
      <Divider variant="middle" />
    </div>
  );
}
