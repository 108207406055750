export function API_URL(relPath: string) {
  return '/api/' + relPath;
  // let p = window.location.pathname + '/api/' + relPath;
  // while (p.includes('//')) {
  //   p = p.replace('//', '/');
  // }
  // return p;
}

export function isDevMode() {
  return window.location.hostname == 'localhost';
}

export function mockDataOrDefault<T>(defaultData: T, testData: T) {
  return isDevMode() ? testData : defaultData;
}

export function mockDataOrNull<T>(testData: T) {
  return isDevMode() ? testData : null;
}

export function toDateString(date: Date) {
  return date.toISOString().substring(0, 10);
}

export function currentDateTimeString() {
  return new Date().toISOString().substring(0, 16);
}

export function shareUrl(shareRelPath: string) {
  const host = isDevMode() ? 'https://wales-armenia.87.org.uk' : '';
  return `${host}/share/${shareRelPath}`;
}

export function shareUrlCss(relPath: string) {
  return `url('${shareUrl(relPath)}')`;
}

export async function promiseDelay(milliseconds: number) {
  await new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function tryCatch<T>(func: () => T, default_?: T) {
  try {
    return func();
  } catch {
    return default_;
  }
}

/** Scroll element into view, considering header offset */
export function scrollToElement(el: Element, extraPadding = 0) {
  const headerHeight = 80; // hardcoded!?
  const elementY = el.getBoundingClientRect().y;
  const scrollY = elementY + window.scrollY - headerHeight - extraPadding;
  window.scrollTo(0, scrollY);
}

export function splitPastUpcomingEvents(events: EventJson[]) {
  const yesterday = new Date().getTime() - 1000 * 60 * 60 * 24;
  const past = events
    .filter(x => new Date(x.date).getTime() < yesterday)
    .sort(compareDateDescending);
  const upcoming = events
    .filter(x => new Date(x.date).getTime() >= yesterday)
    .sort(compareDateAscending);
  return { upcoming, past };
}

const compareDateAscending = (a: EventJson, b: EventJson) =>
  new Date(a.date).getTime() < new Date(b.date).getTime() ? -1 : 1;

const compareDateDescending = (a: EventJson, b: EventJson) =>
  new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1;
