import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { DependencyList, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

export type AddressInputProps = {
  /** unique string for addresses on page (prefixedf) */
  prefix: string;
  /** get/set state */
  value?: Address;
  onChange?: (value: Address) => void;
};

/** Input for an address block */
export function AddressInput(props: AddressInputProps) {
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');

  useEffect(() => {
    if (props.value) {
      setLine1(props.value.line1);
      setLine2(props.value.line2);
      setCity(props.value.city);
      setPostcode(props.value.postcode);
    }
  }, [props.value]);

  useEffect(() => {
    props.onChange && props.onChange({ line1, line2, city, postcode });
  }, [line1, line2, city, postcode]);

  return (
    <>
      <Input
        name={`${props.prefix}AddrLine1`}
        label="Address Line 1"
        value={line1}
        onChange={setLine1}
      />
      <Input
        name={`${props.prefix}AddrLine2`}
        label="Address Line 2"
        optional
        value={line2}
        onChange={setLine2}
      />
      <Row>
        <Col xs={7}>
          <Input
            name={`${props.prefix}AddrCity`}
            label="City"
            value={city}
            onChange={setCity}
          />
        </Col>
        <Col xs={5}>
          <Input
            name={`${props.prefix}AddrPostcode`}
            label="Postcode"
            value={postcode}
            onChange={setPostcode}
          />
        </Col>
      </Row>
    </>
  );
}

export type InputProps = {
  name: string;
  label: string;
  optional?: boolean;
  type?: string;
  value?: string;
  onChange?: (value: string) => void;
};

/** Simple textfield input */
export function Input(props: InputProps) {
  return (
    <TextField
      type={props.type}
      className="mb-2"
      value={props.value}
      onChange={
        props.onChange ? e => props.onChange!(e.target.value) : undefined
      }
      id={props.name}
      name={props.name}
      label={props.label}
      variant="standard"
      fullWidth
      required={!props.optional}
    />
  );
}

type LabelledCheckboxProps = {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

export function LabelledCheckbox(props: LabelledCheckboxProps) {
  return (
    <FormControlLabel
      control={<Checkbox disableRipple />}
      label={props.label}
      checked={props.value}
      onChange={e => props.onChange((e.target as HTMLInputElement).checked)}
    />
  );
}

export function useScrollEffect(fn: () => void) {
  useEffect(() => {
    window.addEventListener('scroll', fn, { passive: true });
    return () => window.removeEventListener('scroll', fn);
  }, []);
}
