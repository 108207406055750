import React, { useEffect, useState } from 'react';
import {
  Location,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import strapDB from '../api/strapDB';
import BasicPage from '../utils/basicPage';
import { useSnackbar } from '../utils/snackbar';
import * as styles from './articleViewerPage.module.scss';
import ArticleViewer from './articleViewer';

export default function ArticlesViewerPage() {
  const [articleData, setArticleData] = useState<ArticleJson | undefined>();
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackbar();
  const basePath = '/' + location.pathname.split('/')[1]!;

  const id = resolveArticleID(params, location);
  if (!id) {
    useEffect(handleArticleFetchError);
    return <></>;
  }

  if (!isLoaded) {
    setIsLoaded(true);
    strapDB.article.fetchOne(id, 'useCache').then(value => {
      if (value) setArticleData(value);
      else handleArticleFetchError();
    });
    return <></>;
  } else if (!articleData) {
    return <></>;
  }

  function handleArticleFetchError() {
    snackbar.error(`Invalid article ID: ${id}`);
    navigate(basePath, { replace: true });
  }

  return (
    <BasicPage
      className={styles.articleViewerPage}
      title={articleData.headline}
    >
      <ArticleViewer articleData={articleData} />
    </BasicPage>
  );
}

function resolveArticleID(params: Params<string>, location: Location) {
  if (location.pathname == '/genocide-information') {
    // TODO: find first article with the articletype genocide?
    return 32;
  } else if (location.pathname == '/past-events') {
    return 37;
  }
  const val = parseInt(params.id || '');
  return isNaN(val) ? undefined : val;
}
