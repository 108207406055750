import { defaultGetCulture, mockDataOrDefault } from '../utils/functions';
import { defaultGetSectionsResp } from './getSections';

export const defaultGetBooksResp = mockDataOrDefault<BookJson[]>(
  [],
  [
    {
      id: 10151,
      title: 'Armenia: the continuing tragedy',
      author: {
        id: 76,
        name: 'Affairs, Commission of the Churches on ',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10085,
      title: 'The Art of Letter-Type, 120 Tables of Armenian Decorative Types',
      author: {
        id: 17,
        name: 'Africkian, Fred',
      },
      section: {
        id: 5,
        name: 'Language',
      },
    },
    {
      id: 10150,
      title: 'Armenian Genocide Resource Guide',
      author: {
        id: 75,
        name: 'America, Armenian Assembly of',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10165,
      title: 'The Green Hat',
      author: {
        id: 91,
        name: 'Arlen, Michael J.',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10164,
      title: 'Passage to Ararat',
      author: {
        id: 91,
        name: 'Arlen, Michael J.',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10118,
      title: 'Stories of Mulla Nasr-Ed-Din',
      author: {
        id: 52,
        name: 'Aryanapur, Abbas',
      },
      section: {
        id: 11,
        name: 'Stories',
      },
    },
    {
      id: 10097,
      title: 'English-Armenian Dictionary',
      author: {
        id: 34,
        name: 'Asmangulian, H. A.',
      },
      section: {
        id: 5,
        name: 'Language',
      },
    },
    {
      id: 10160,
      title: 'Armenian-Americans, From Being to Feeling Armenian',
      author: {
        id: 88,
        name: 'Bakalian, Anny',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10139,
      title: 'Black Dog of Fate, An American son uncovers his Armenian past',
      author: {
        id: 25,
        name: 'Balakian, Peter',
      },
      section: {
        id: 4,
        name: 'Biography',
      },
    },
    {
      id: 10161,
      title: 'Hitler and the Armenian Genocide, Special Report No. 3',
      author: {
        id: 36,
        name: 'Bardakjian, Kevork B.',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10098,
      title:
        'A Reference Guide to Modern Armenian Literature, 1500-1920, With an Introductory History',
      author: {
        id: 36,
        name: 'Bardakjian, Kevork B.',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10072,
      title: 'Love, Hate and Indifference, The slide into genocide',
      author: {
        id: 6,
        name: 'Barnett, Ruth',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10104,
      title:
        'The Great Game of Genocide, Imperialism, Nationalism, and the Destruction of the Ottoman Armenians',
      author: {
        id: 43,
        name: 'Bloxham, Donald',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10132,
      title: 'Artsakh, the people and land of Karabakh',
      author: {
        id: 62,
        name: 'Bronowski, J.',
      },
      section: {
        id: 7,
        name: 'Travel',
      },
    },
    {
      id: 10129,
      title: 'The Ascent of Man',
      author: {
        id: 62,
        name: 'Bronowski, J.',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10128,
      title:
        'Assyrian Genocide 1915, Genocide. Denial and the Right of Recognition, European Parliament Conference, Monday 26th of March 2007',
      author: {
        id: 61,
        name: 'Center, Seyfo',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10166,
      title: 'The Armenians, From Genocide to Resistance',
      author: {
        id: 92,
        name: 'Chaliand, Gerard',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10148,
      title: 'Black Garden, Armenia and Azerbaijan through Peace and War',
      author: {
        id: 74,
        name: 'De Waal, Thomas',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10163,
      title: 'Seven Songs About Armenia',
      author: {
        id: 90,
        name: 'Emin, Gevork',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10157,
      title:
        'Armenians in 2115, Strategic Directions for the Twenty First Century',
      author: {
        id: 77,
        name: 'Fondation, Calouste Gulbenkian',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10152,
      title: 'Armenian Department, Twenty years of activity, 1956-1976',
      author: {
        id: 77,
        name: 'Fondation, Calouste Gulbenkian',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10124,
      title: 'The World is Flat, A brief history of the twenty-first century',
      author: {
        id: 57,
        name: 'Friedman, Thomas L.',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10133,
      title: 'Armenian Heritage and Unesco',
      author: {
        id: 63,
        name: 'Gabrielyan, Vahe',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10135,
      title: 'Goodbye Istanbul!, B.O.A.C. fly me to London',
      author: {
        id: 66,
        name: 'Gorgodian, Anahid',
      },
      section: {
        id: 4,
        name: 'Biography',
      },
    },
    {
      id: 10134,
      title: 'Elementary Modern Armenian Grammer',
      author: {
        id: 64,
        name: 'Gulian, Kevork H.',
      },
      section: {
        id: 5,
        name: 'Language',
      },
    },
    {
      id: 10096,
      title: 'Meeting With Remarkable Men',
      author: {
        id: 33,
        name: 'Gurdjieff, G.',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10110,
      title: 'The Armenian Image in History and Literature',
      author: {
        id: 46,
        name: 'Hovannisian, Richard G.',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10107,
      title: 'Remembrance and Denial, The Case of the Armenian Genocide',
      author: {
        id: 46,
        name: 'Hovannisian, Richard G.',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10112,
      title: 'Democracy Building and Civil Society in Post-Society Armenia',
      author: {
        id: 50,
        name: 'Ishkanian, Armine',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10084,
      title: 'The Backgammon Book',
      author: {
        id: 16,
        name: 'Jacoby, Oswald',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10071,
      title: 'Memory',
      author: {
        id: 5,
        name: 'Jacquart, Delphine',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10111,
      title: 'The Caucasian Republics',
      author: {
        id: 49,
        name: 'Kaeter, Margaret',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10086,
      title: 'Armenian Miniature Ornate Initials',
      author: {
        id: 18,
        name: 'Karagueuzian, H. W.',
      },
      section: {
        id: 5,
        name: 'Language',
      },
    },
    {
      id: 10159,
      title: 'The Cilician Armenian Ordeal',
      author: {
        id: 87,
        name: 'Kazanjian, Paren',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10094,
      title: 'Armenia, A country and the people, A guidebook',
      author: {
        id: 29,
        name: 'Khudaverdyan, Konstantin',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10099,
      title: 'The Saga of Sassoun',
      author: {
        id: 40,
        name: 'Kudian, Mischa',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10106,
      title: 'Soviet Armenian Poetry',
      author: {
        id: 40,
        name: 'Kudian, Mischa',
      },
      section: {
        id: 10,
        name: 'Poems',
      },
    },
    {
      id: 10102,
      title: 'More Apples Fell From Heaven, Armenian folk and fairy tales',
      author: {
        id: 40,
        name: 'Kudian, Mischa',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10088,
      title: 'Archeology of Madness, Komitas, Portrait of an Armenian Icon',
      author: {
        id: 19,
        name: 'Kuyumjian, Rita Soulahian',
      },
      section: {
        id: 4,
        name: 'Biography',
      },
    },
    {
      id: 10076,
      title: 'The Armenians, A People in Exile',
      author: {
        id: 8,
        name: 'Lang, David Marshall',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10158,
      title: 'Saroyan, A biography',
      author: {
        id: 86,
        name: 'Lee, Lawrence',
      },
      section: {
        id: 4,
        name: 'Biography',
      },
    },
    {
      id: 10127,
      title: 'A History of the World in 100 Objects',
      author: {
        id: 60,
        name: 'MacGregor, Neil',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10083,
      title: 'Anyush',
      author: {
        id: 15,
        name: 'Madden, Martine',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10093,
      title: 'Armenian Info Text',
      author: {
        id: 28,
        name: 'Mouradian, George',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10082,
      title:
        'Speaking with God from the Depths of the Heart, The Armenia Prayer Book of St. Gregory of Narek',
      author: {
        id: 14,
        name: 'Narekatsi, St. Grigor',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10109,
      title: 'The Tondrakian Movement',
      author: {
        id: 48,
        name: 'Nersessian, Vrej',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10149,
      title:
        'The Repatriation of an Armenian Cultural Treasure, The Gospel of Queen Keran, Sis, Cilicia, 1272 A.D.',
      author: {
        id: 48,
        name: 'Nersessian, Vrej',
      },
      section: {
        id: 6,
        name: 'Religion',
      },
    },
    {
      id: 10154,
      title: 'Catalogue of Early Armenian Books, 1512-1850',
      author: {
        id: 48,
        name: 'Nersessian, Vrej',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10155,
      title: 'Armenian Illuminated Gospel Books',
      author: {
        id: 48,
        name: 'Nersessian, Vrej',
      },
      section: {
        id: 6,
        name: 'Religion',
      },
    },
    {
      id: 10101,
      title: 'The Armenian General Benevolent Union',
      author: {
        id: 41,
        name: 'Norehad, Bedros',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10120,
      title: 'Istanbul, Hatiralar ve sehir',
      author: {
        id: 53,
        name: 'Pamuk, Orhan',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10119,
      title: 'Kar',
      author: {
        id: 53,
        name: 'Pamuk, Orhan',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10116,
      title: 'Fire and Sword in the Sudan',
      author: {
        id: 51,
        name: 'Pasha, R. Slatin',
      },
      section: {
        id: 4,
        name: 'Biography',
      },
    },
    {
      id: 10089,
      title:
        'The Times of the Armenian Genocide, Reports in the British Press, Volume 2: 1920-1923',
      author: {
        id: 21,
        name: 'Peltekian, Katia Minas',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10105,
      title: 'Tajkahayk, Armenian Literature in Translation',
      author: {
        id: 45,
        name: 'Raffi',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10080,
      title:
        'The Gambler, How penniless dropout Kirk Kerkorian became the greatest deal maker in capitalist history',
      author: {
        id: 12,
        name: 'Rempel, William C',
      },
      section: {
        id: 4,
        name: 'Biography',
      },
    },
    {
      id: 10123,
      title: 'Nasrettin Hoca',
      author: {
        id: 56,
        name: 'Resimlerle, Renkli',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10092,
      title: 'The Council of Chalcedon and the Armenian Church',
      author: {
        id: 27,
        name: 'Sarkissian, Karekin',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10078,
      title: 'Hayastan',
      author: {
        id: 10,
        name: 'Sarkisyan, Sarkis',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10073,
      title: 'In the Shadow of the Sultan',
      author: {
        id: 7,
        name: 'Sevadjian, R.P.',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10122,
      title: 'The Bastard of Istanbul',
      author: {
        id: 55,
        name: 'Shafak, Elif',
      },
      section: {
        id: 9,
        name: 'Fiction',
      },
    },
    {
      id: 10144,
      title:
        'The Happiest People on Earth, The long-awaited personal story of Demos Shakarian',
      author: {
        id: 69,
        name: 'Sherrill, John & Elizabeth',
      },
      section: {
        id: 4,
        name: 'Biography',
      },
    },
    {
      id: 10091,
      title: 'Bloody News from My Friend',
      author: {
        id: 24,
        name: 'Siamanto, ',
      },
      section: {
        id: 10,
        name: 'Poems',
      },
    },
    {
      id: 10077,
      title: 'Geek Girl',
      author: {
        id: 9,
        name: 'Smale, Holly',
      },
      section: {
        id: 9,
        name: 'Fiction',
      },
    },
    {
      id: 10153,
      title: 'Architectural monuments in Soviet Armenia',
      author: {
        id: 78,
        name: 'SSR, Institute of Arts, Academy of ',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10143,
      title: 'Lectures on the History of the Eastern Church',
      author: {
        id: 68,
        name: 'Stanley, A. P.',
      },
      section: {
        id: 6,
        name: 'Religion',
      },
    },
    {
      id: 10081,
      title: 'Looking Toward Ararat, Armenia in Modern History',
      author: {
        id: 13,
        name: 'Suny, Ronald Grigor',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10146,
      title: 'The Armenian Genocide and Historical Memory',
      author: {
        id: 71,
        name: 'Svazlian, Verjine',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10162,
      title:
        'The Truth About the massacres and deportations of the Armenian population of the Ottoman Empire 1915',
      author: {
        id: 89,
        name: 'Tarkmanian, Hagop A.',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10079,
      title: 'Ararat',
      author: {
        id: 11,
        name: 'Thomas, D M',
      },
      section: {
        id: 9,
        name: 'Fiction',
      },
    },
    {
      id: 10075,
      title: 'From Carmarthen to Karabagh, a Welsh Discovery of Armenia',
      author: {
        id: 4,
        name: 'Thomas, Patrick',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10070,
      title: 'Carols Before Dawn and other Welsh Christmases',
      author: {
        id: 4,
        name: 'Thomas, Patrick',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10074,
      title: 'Remembering The Armenian Genocide 1915',
      author: {
        id: 4,
        name: 'Thomas, Patrick',
      },
      section: {
        id: 2,
        name: 'Genocide',
      },
    },
    {
      id: 10095,
      title:
        'Redefining Diasporas: Old Approaches, New Identies, The Armenian Diaspora in an International Context',
      author: {
        id: 32,
        name: 'Tololyan, Khachig',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10140,
      title: 'Tell Me, Bella',
      author: {
        id: 67,
        name: 'Totovents, Vahan',
      },
      section: {
        id: 11,
        name: 'Stories',
      },
    },
    {
      id: 10125,
      title:
        'Sofraniz Sen Olsun, ninelerimin mutfagindan damagimda, aklimda kalanlar',
      author: {
        id: 58,
        name: 'Tovmasyan, Takuhi',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10130,
      title: 'A Theology of War, Faith and Evangelism in Karabakh',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10113,
      title: 'MerhaBarev, Armenian-Turkey joint photoproject',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10114,
      title: 'Tales From Rumi',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10115,
      title: 'Persian Poems',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 10,
        name: 'Poems',
      },
    },
    {
      id: 10090,
      title: 'Hovhannes Aivazovsky',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 4,
        name: 'Biography',
      },
    },
    {
      id: 10117,
      title: 'Pocket World in Figures',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10121,
      title: 'Turkey, Eyewitness travel guides',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 7,
        name: 'Travel',
      },
    },
    {
      id: 10087,
      title: 'The Christian Orient',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10156,
      title: 'Armenian Soviet Socialist Republic',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10142,
      title: 'EU Enlargement, Turkey: Progress Report & Conclusions',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10131,
      title: 'Nagorno Karabagh, A white paper',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10100,
      title: 'Calouste Sarkis Gulbenkian, The Man and His Achievements',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10136,
      title: 'Keram, Armenian traditional music',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10137,
      title: "The World's Armenian Composer",
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10138,
      title: 'The Armenian Church of St Sarkis',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10141,
      title: 'EU Enlargement, Strategy 2010-2011',
      author: {
        id: 1002,
        name: 'Unknown Author',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10147,
      title: 'Armenia Reborn',
      author: {
        id: 72,
        name: 'Vertanes, Charles A.',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10108,
      title: 'Armenia, The Survival of a Nation',
      author: {
        id: 47,
        name: 'Walker, Christopher J.',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10145,
      title: 'Visions of Ararat, Writings on Armenia',
      author: {
        id: 47,
        name: 'Walker, Christopher J.',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
    {
      id: 10103,
      title: 'Ararat, In Search of the Mythical Mountain',
      author: {
        id: 42,
        name: 'Westerman, Frank',
      },
      section: {
        id: 1,
        name: 'Culture',
      },
    },
    {
      id: 10126,
      title:
        'The Story of England, A village and its people through the whole of English history',
      author: {
        id: 59,
        name: 'Wood, Michael',
      },
      section: {
        id: 3,
        name: 'History',
      },
    },
  ],
);

export const defaultGetBooksRawResp = defaultGetBooksResp.map(
  book =>
    ({
      ...book,
      section: book.section.id,
      author: book.author?.id,
    } as BookJsonRaw),
);
