import classNames from 'classnames';
import { Button } from '@mui/material';
import React, { CSSProperties, ReactNode } from 'react';
import { Container } from 'react-bootstrap';

type SectionProps = {
  name: string;
  contrast?: boolean;
  noBottom?: boolean;
  children: ReactNode | ReactNode[];
  style?: CSSProperties;
};

export function Section(props: SectionProps) {
  return (
    <section
      style={props.style}
      className={classNames('home-section', `home-section--${props.name}`, {
        'home-section--contrast': props.contrast,
        'home-section--noBottom': props.noBottom,
      })}
    >
      <Container>{props.children}</Container>
    </section>
  );
}

type ActionProps = {
  href: string;
  children: ReactNode;
};

export function Action(props: ActionProps) {
  return (
    <Button href={props.href} size="large" variant="contained" disableRipple>
      {props.children}
    </Button>
  );
}

export function Heading({ children }: { children: ReactNode }) {
  return <h2>{children}</h2>;
}

export function Brief({ children }: { children: ReactNode }) {
  return <p>{children}</p>;
}
