.uploaderItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #eee;
}

.spinner {
    margin-bottom: 4px;
}