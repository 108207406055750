import { Card } from '@mui/material';
import classNames from 'classnames';
import React, { LegacyRef, useRef, useState } from 'react';
import { ReactNode } from 'react';
import { useScrollEffect } from './miscComponents';
import * as styles from './navbarAdornment.module.scss';

type NavbarAdornmentProps = {
  variant: 'fixed' | 'sticky';
  position: 'pushedOut' | 'end';
  children: ReactNode | ReactNode[];
  onClick?: () => void;
};

export default function NavbarAdornment(props: NavbarAdornmentProps) {
  const [isSticky, setIsSticky] = useState(props.variant == 'fixed');
  const rootRef = useRef<HTMLDivElement>();

  useScrollEffect(() => {
    if (props.variant == 'sticky' && rootRef.current) {
      setIsSticky(rootRef.current?.getBoundingClientRect().top == 80);
    }
  });

  return (
    <div
      // Need legacy ref for MUI. The difference seems to be using null
      // vs undefined for the sentinel value.
      ref={rootRef as LegacyRef<HTMLDivElement>}
      className={classNames({
        [styles.shadowWrap]: true,
        [styles.shadowWrapFixed]: props.variant == 'fixed',
        [styles.shadowWrapPushed]: props.position == 'pushedOut',
      })}
    >
      <Card
        className={styles.mainCard}
        variant={isSticky ? 'elevation' : 'outlined'}
        elevation={isSticky ? 6 : 0}
        onClick={props.onClick}
      >
        {props.children}
      </Card>
    </div>
  );
}
