import { Divider, Icon, IconButton, Stack, Typography } from '@mui/material';
import * as styles from './bookList.module.scss';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isLoggedInAsAdmin } from '../api/adminAPI';

type BookListProps = {
  books: BookJson[];
};

export default function BookList(props: BookListProps) {
  return (
    <Stack className={styles.bookList} divider={<Divider variant="inset" />}>
      {props.books.map(book => (
        <BookListing book={book} key={book.id} />
      ))}
    </Stack>
  );
}

function BookListing({ book }: { book: BookJson }) {
  const adminMode = isLoggedInAsAdmin();
  const navigate = useNavigate();

  return (
    <div className={styles.bookListing}>
      {adminMode && (
        <div className={styles.adminInlineToolStrip}>
          <IconButton
            onClick={() => navigate(`/library/book/${book.id}/edit`)}
            size="small"
            color="primary"
          >
            <Icon>edit</Icon>
          </IconButton>
        </div>
      )}
      <div className={styles.infoReadout}>
        <Typography variant="body1">
          {book.title}
          {book.titleTranslation && ' / ' + book.titleTranslation}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {book.author?.name ?? 'Unknown Author'}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          In: {book.section.name}
        </Typography>
      </div>
    </div>
  );
}
