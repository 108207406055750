import { mockDataOrDefault } from '../utils/functions';

export const defaultGetArticlesResp = mockDataOrDefault<ArticleJson[]>(
  [],
  [
    {
      id: 1,
      headline: 'Example With Image',
      byline: 'My Name',
      publishedOn: '2027-03-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8ni6f","text":"📷","type":"atomic","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":1,"key":0}],"data":{}}],"entityMap":{"0":{"type":"IMAGE","mutability":"IMMUTABLE","data":{"src":"https://th.bing.com/th/id/OIP.W8apTKARTqVhmLzMCisNmAHaEs?pid=ImgDet&rs=1"}}}}',
      brief: 'asd',
      articleType: 0,
    },
    {
      id: 37,
      headline: 'Example With Video',
      byline: 'My Name',
      publishedOn: '2027-03-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"4niio","text":" ","type":"atomic","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":1,"key":0}],"data":{}},{"key":"3i92b","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"draft-js-video-plugin-video","mutability":"IMMUTABLE","data":{"src":"https://www.youtube.com/watch?v=CXJHt7r7n7c"}}}}',
      brief: 'asd',
      articleType: 1,
    },
    {
      id: 911,
      headline: 'Past Event 2022 Long Long Long Long Headline Text',
      byline: 'My Name',
      publishedOn: '2022-03-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8ni6f","text":"📷","type":"atomic","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":1,"key":0}],"data":{}}],"entityMap":{"0":{"type":"IMAGE","mutability":"IMMUTABLE","data":{"src":"https://th.bing.com/th/id/OIP.W8apTKARTqVhmLzMCisNmAHaEs?pid=ImgDet&rs=1"}}}}',
      brief: 'asd',
      articleType: 1,
    },
    {
      id: 912,
      headline: 'Past Event 2020 mar',
      byline: 'My Name',
      publishedOn: '2020-03-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"8ni6f","text":"📷","type":"atomic","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":1,"key":0}],"data":{}}],"entityMap":{"0":{"type":"IMAGE","mutability":"IMMUTABLE","data":{"src":"https://th.bing.com/th/id/OIP.W8apTKARTqVhmLzMCisNmAHaEs?pid=ImgDet&rs=1"}}}}',
      brief: 'asd',
      articleType: 1,
    },
    {
      id: 913,
      headline: 'Past Event 2020 dec',
      byline: 'My Name',
      publishedOn: '2020-12-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5s4um","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"9otm","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3b3gl","text":"ads","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      brief: 'asd',
      articleType: 1,
    },
    {
      id: 914,
      headline: 'Past Event 2020 december',
      byline: 'My Name',
      publishedOn: '2020-12-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5s4um","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"9otm","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3b3gl","text":"ads","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      brief: 'asd',
      articleType: 1,
    },
    {
      id: 32,
      headline: 'Headline With Longer Long Text Headline With Longer Long Text',
      byline: 'My Name',
      publishedOn: '2027-03-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5s4um","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"9otm","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3b3gl","text":"ads","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      brief: 'asd',
      articleType: 2,
    },
    {
      id: 12,
      headline: 'Community News Section',
      byline: 'At the memorial on 6th November - Sunday.',
      publishedOn: '2027-03-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5s4um","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"9otm","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3b3gl","text":"ads","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      brief: 'asd',
      articleType: 3,
    },
    {
      id: 14,
      headline: 'Community News 2',
      byline: 'My Name',
      publishedOn: '2027-03-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5s4um","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"9otm","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3b3gl","text":"ads","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      brief: 'asd',
      articleType: 3,
    },
    {
      id: 15,
      headline: 'Community News 3',
      byline: 'My Name',
      publishedOn: '2027-03-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5s4um","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"9otm","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3b3gl","text":"ads","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      brief: 'asd',
      articleType: 3,
    },
    {
      id: 4,
      headline: 'Headline 4',
      byline: 'My Name',
      publishedOn: '2027-03-10',
      content:
        '{"blocks":[{"key":"5jjk9","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5s4um","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"9otm","text":"asd","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3b3gl","text":"ads","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      brief: 'asd',
      articleType: 0,
    },
    {
      id: 5,
      headline: 'Example With Link',
      publishedOn: '2027-03-10',
      byline: 'My Name',
      content:
        '{"blocks":[{"key":"5jjk9","text":"http://localhost:1234/articles/4","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      brief: 'http://localhost:1234/articles/4',
      articleType: 0,
    },
  ],
);
