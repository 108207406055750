import * as React from 'react';
import { Icon, IconButton, Menu, MenuItem } from '@mui/material';
import { ShopSortType } from '../utils/enums';
import { useSearchParams } from 'react-router-dom';
import { SORT_KEY } from './utils';

type ShopSortProps = {
  onSelect: (value: ShopSortType) => void;
};

const BUTTON_ID = 'shop-sort-button';
const MENU_ID = 'shop-sort-menu';

const SORT_OPTION_NAMES: Record<ShopSortType, string> = {
  [ShopSortType.DateAddedAscending]: 'Date Added (Oldest First)',
  [ShopSortType.DateAddedDescending]: 'Date Added (Latest First)',
  [ShopSortType.PriceAscending]: 'Price (Low to High)',
  [ShopSortType.PriceDescending]: 'Price (High to Low)',
  [ShopSortType.AToZ]: 'Alphabetical',
};

export default function ShopSort(props: ShopSortProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = (_a: any, _b: any, selectedOption?: ShopSortType) => {
    setAnchorEl(null);
    if (selectedOption) {
      searchParams.set(SORT_KEY, selectedOption.toString());
      setSearchParams(searchParams);
      props.onSelect(selectedOption);
    }
  };

  return (
    <div>
      <IconButton
        disableTouchRipple={true}
        id={BUTTON_ID}
        aria-controls={open ? MENU_ID : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Icon fontSize="large">sort</Icon>
      </IconButton>
      <Menu
        id={MENU_ID}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': BUTTON_ID,
        }}
      >
        {Object.entries(SORT_OPTION_NAMES).map(([sortType, displayName]) => (
          <MenuItem
            disableRipple={true}
            onClick={() =>
              handleClose(null, null, sortType as unknown as ShopSortType)
            }
            key={sortType}
          >
            {displayName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
