import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import NavBar from './utils/navBar';
import * as Pages from './utils/pages';
import './mainStyle.sass';
import ScrollToTop from './utils/scrollToTop';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const theme = createTheme({
  palette: {
    primary: { main: '#f2b200' },
    secondary: { main: '#000' },
  },
});

const g_stripePublicKey =
  'pk_live_51LibZMCWev50zb2RbHNP77JJI65WpgTHkqw4BH9Bu76tDW1gsY7brqZPWmQ4vufC1HqVkW0kgcwgmKMMY4fxxgUq00NsRwZDb6';
const g_stripePromise = loadStripe(g_stripePublicKey);

export default () => {
  return (
    <Elements stripe={g_stripePromise}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <ScrollToTop />
            <NavBar />
            <Routes>
              <Route index element={<Pages.HomePage />} />
              <Route path="communityNews">
                <Route index element={<Pages.ArticlesIndexPage />} />
                <Route path=":id">
                  <Route index element={<Pages.ArticleViewerPage />} />
                  <Route path="edit" element={<Pages.ArticleEditorPage />} />
                </Route>
              </Route>
              <Route path="about">
                <Route index element={<Pages.AboutPage />} />
                <Route path=":id">
                  <Route index element={<Pages.ArticleViewerPage />} />
                  <Route path="edit" element={<Pages.ArticleEditorPage />} />
                </Route>
                <Route path="communityNews">
                  <Route index element={<Pages.ArticlesIndexPage />} />
                  <Route path="new" element={<Pages.ArticleEditorPage />} />
                  <Route path=":id">
                    <Route index element={<Pages.ArticleViewerPage />} />
                    <Route path="edit" element={<Pages.ArticleEditorPage />} />
                  </Route>
                </Route>
                <Route path="new" element={<Pages.ArticleEditorPage />} />
              </Route>
              <Route path="past-events">
                <Route index element={<Pages.ArticleViewerPage />} />
                <Route path=":id">
                  <Route index element={<Pages.ArticleViewerPage />} />
                  <Route path="edit" element={<Pages.ArticleEditorPage />} />
                </Route>
                <Route path="new" element={<Pages.ArticleEditorPage />} />
              </Route>
              <Route
                path="genocide-information"
                element={<Pages.ArticleViewerPage />}
              />
              <Route path="articles">
                <Route index element={<Pages.ArticlesIndexPage />} />
                <Route path=":id">
                  <Route index element={<Pages.ArticleViewerPage />} />
                  <Route path="edit" element={<Pages.ArticleEditorPage />} />
                </Route>
                <Route path="new" element={<Pages.ArticleEditorPage />} />
              </Route>

              <Route path="events">
                <Route index element={<Pages.EventsPage />} />
                <Route path=":id/edit" element={<Pages.ArticleEditorPage />} />
                <Route path="new" element={<Pages.ArticleEditorPage />} />
              </Route>

              <Route path="library">
                <Route index element={<Pages.Library />} />
                <Route path="ingest" element={<Pages.BookEditorPage />} />
                <Route
                  path="book/:bookID/edit"
                  element={<Pages.BookEditorPage />}
                />
              </Route>

              <Route path="shop">
                <Route index element={<Pages.ShopPage />} />
                <Route path="orders" element={<Pages.OrdersPage />} />
                <Route path="product">
                  <Route path=":productID">
                    <Route index element={<Pages.ProductViewerPage />} />
                    <Route path="edit" element={<Pages.ProductEditPage />} />
                  </Route>
                  <Route path="new" element={<Pages.ProductEditPage />} />
                </Route>
                <Route path="cart" element={<Pages.CartPage />} />
                <Route path="shipping" element={<Pages.ShippingPage />} />
                <Route path="payment" element={<Pages.PaymentPage />} />
              </Route>
              <Route path="admin">
                <Route index element={<Pages.AdminIndexPage />} />
                <Route path="login" element={<Pages.AdminLoginPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </Elements>
  );
};
