import { Button, Icon, MenuItem, Select } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BasicPage from '../utils/basicPage';
import * as styles from './shippingPage.module.scss';
import CheckoutStepper from './checkoutStepper';
import { AddressInput, Input, LabelledCheckbox } from '../utils/miscComponents';
import { useSessionStorage } from 'usehooks-ts';
import { formatCompactCurrency } from '../utils/stringUtils';
import { defaultGetDeliveryOptionsResp } from '../mockData/getDeliveryOptions';
import { useIsLoggedInAsAdmin } from '../admin/adminUtils';
import DeliveryOptionEditDialog from './deliveryOptionEditDialog';
import strapDB from '../api/strapDB';

export default function ShippingPage() {
  const [checkoutForm, setCheckoutForm] = useCheckoutForm();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [shippingAddr, setShippingAddr] = useState<Address | undefined>();
  const [customerCollects, setCustomerCollects] = useState(false);

  // Clear billing addr on check same as shipping
  useEffect(() => {
    if (customerCollects) setShippingAddr(undefined);
  }, [customerCollects]);

  function onClickBack() {
    navigate('/shop/cart');
  }

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    setCheckoutForm({
      firstName,
      lastName,
      email,
      shippingAddr,
      customerCollects,
    });
    navigate('/shop/payment');
  }

  useEffect(() => {
    if (checkoutForm) {
      setFirstName(checkoutForm.firstName);
      setLastName(checkoutForm.lastName);
      setEmail(checkoutForm.email);
      setShippingAddr(checkoutForm.shippingAddr);
      setCustomerCollects(checkoutForm.customerCollects);
    }
  }, [checkoutForm]);

  return (
    <BasicPage title="Checkout">
      <CheckoutStepper currentStep={1} />
      <form className={styles.form} onSubmit={onSubmit}>
        <Row>
          <Col lg={3} md={2} />
          <Col lg={6} md={8}>
            <h5 className="mt-0">Personal Information</h5>
            <Row>
              <Col sm={6}>
                <Input
                  name="firstName"
                  label="First Name"
                  value={firstName}
                  onChange={setFirstName}
                />
              </Col>
              <Col sm={6}>
                <Input
                  name="lastName"
                  label="Last Name"
                  value={lastName}
                  onChange={setLastName}
                />
              </Col>
            </Row>
            <Input
              name="email"
              label="Email Address"
              type="email"
              value={email}
              onChange={setEmail}
            />
            <h5>Shipping Address</h5>
            <LabelledCheckbox
              label="Customer Collection Instead of Delivery"
              value={customerCollects}
              onChange={setCustomerCollects}
            />
            {!customerCollects && (
              <AddressInput
                prefix="shipping"
                value={shippingAddr}
                onChange={setShippingAddr}
              />
            )}
            <div className={styles.actions}>
              <Button className="mt-4" disableRipple onClick={onClickBack}>
                Back
              </Button>
              <Button
                className="mt-4"
                type="submit"
                variant="contained"
                disableRipple
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </BasicPage>
  );
}

/** @deprecated */
type DeliveryOptionInputProps = {
  value: number;
  onChange: (value: number) => void;
  options: DeliveryOptionJson[];
  setOptions: (value: DeliveryOptionJson[]) => void;
};

/** @deprecated */
function DeliveryOptionInput(props: DeliveryOptionInputProps) {
  const [isEditing, setIsEditing] = useState(false);
  const isAdminMode = useIsLoggedInAsAdmin();

  useEffect(() => {
    if (!isEditing) {
      strapDB.deliveryOption.fetchAll('useCache').then(x => {
        props.setOptions(x);
        if (x.length == 0) {
          // In case all options were deleted.
          props.onChange(0);
        } else if (!props.value || !x.find(y => y.id == props.value)) {
          // Set to first option if not selected anything or existing
          // id was deleted.
          props.onChange(x[0]!.id);
        }
      });
    }
  }, [isEditing]);

  function onEditDeliveryOptions() {
    setIsEditing(true);
  }

  function onCloseEditDialog() {
    setIsEditing(false);
    // fetch again as its not working with caching?
    strapDB.deliveryOption.fetchAll().then(props.setOptions);
  }

  return (
    <>
      <DeliveryOptionEditDialog
        isOpen={isEditing}
        onClose={onCloseEditDialog}
      />
      <Select
        variant="standard"
        value={props.value || ''}
        onChange={e =>
          e.target.value == 'edit'
            ? onEditDeliveryOptions()
            : props.onChange(+e.target.value)
        }
        fullWidth
      >
        {isAdminMode && (
          <MenuItem value="edit">
            <Icon fontSize="small">edit</Icon>&nbsp;Edit Options
          </MenuItem>
        )}
        {props.options.map(it => (
          <MenuItem key={it.id} value={it.id} disableRipple>
            {it.name} (+{formatCompactCurrency(it.cost)})
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export function useCheckoutForm() {
  const key = 'checkout';
  return useSessionStorage<CheckoutForm | undefined>(key, undefined);
}
