import {
  SnackbarAction,
  useSnackbar as notistack_useSnackbar,
} from 'notistack';

export function useSnackbar() {
  const { enqueueSnackbar } = notistack_useSnackbar();

  return {
    success: (message: string, data?: { action?: SnackbarAction }) =>
      enqueueSnackbar(message, {
        variant: 'success',
        autoHideDuration: 3000,
        ...data,
      }),
    error: (message: string, data?: { action?: SnackbarAction }) =>
      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 6000,
        ...data,
      }),
  };
}
