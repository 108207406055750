import axios from 'axios';
import { isDevMode } from '../../utils/functions';

type AdminLoginSubmission = {
  username: string;
  password: string;
};

export async function submitAdminLogin(submission: AdminLoginSubmission) {
  const success = isDevMode()
    ? submission.username == 'test'
    : await submitLoginForm(submission);
  sessionStorage.setItem('isLoggedInAsAdmin', String(success));
  return success;
}

async function submitLoginForm(submission: AdminLoginSubmission) {
  const form = new FormData();
  form.append('username', submission.username);
  form.append('password', submission.password);
  const result = await axios.post('/api/adminAPI/adminLogin.php', form);
  return (result.data as PostAdminLoginResp).loginSuccess;
}

export function isLoggedInAsAdmin() {
  return sessionStorage.getItem('isLoggedInAsAdmin') == 'true';
}

export async function isLoggedInAsAdmin2() {
  if (isDevMode()) return isLoggedInAsAdmin();
  const { isLoggedIn } = (await axios.get(
    '/api/adminAPI/adminIsLoggedIn.php',
  )).data as GetAdminIsLoggedInResp;
  sessionStorage.setItem('isLoggedInAsAdmin', String(isLoggedIn));
  return isLoggedIn;
}

export async function logOutAsAdmin() {
  sessionStorage.removeItem('isLoggedInAsAdmin');
  if (!isDevMode()) {
    await axios.post('/api/adminAPI/adminLogout.php');
  }
}

