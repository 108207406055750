import { Button, Divider, Icon, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BasicPage from '../utils/basicPage';
import { isLoggedInAsAdmin } from '../api/adminAPI';
import strapDB from '../api/strapDB';
import * as styles from './articlesIndexPage.module.scss';
import { ArticleType } from '../utils/enums';

export default function ArticlesIndexPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [articlesData, setArticlesData] = useState<ArticleJson[]>([]);
  const [refetch, setRefetch] = useState(true);
  if (refetch) {
    setRefetch(false);
    strapDB.article.fetchAll('useCache').then(setArticlesData);
  }
  const isAdmin = isLoggedInAsAdmin();

  const articleType = {
    '/about': ArticleType.About,
    '/past-events': ArticleType.PastEvent,
    '/about/communityNews': ArticleType.CommunityNews,
  }[location.pathname];
  const articlesToShow =
    articleType !== undefined
      ? articlesData.filter(x => x.articleType == articleType)
      : articlesData;

  const documentTitle =
    {
      '/about': 'About',
      '/past-events': 'Past Events',
      '/about/communityNews': 'Community News',
    }[location.pathname] || 'Articles';

  return (
    <BasicPage className={styles.articleIndexPage} title={documentTitle}>
      <Stack divider={<Divider variant="middle" />} gap={3}>
        {isAdmin && (
          <div className={styles.articleListing}>
            <Button
              color="primary"
              onClick={() => navigate(location.pathname + '/new')}
              size="large"
              variant="contained"
              startIcon={<Icon>add</Icon>}
              disableRipple
            >
              Create Article
            </Button>
          </div>
        )}
        {articlesToShow.map(i => (
          <div className={styles.articleListing} key={i.id}>
            <Link
              className={styles.articleTitleLink}
              to={`${location.pathname}/${i.id}`}
            >
              <h5>{i.headline}</h5>
            </Link>
            <p className={styles.articleBrief}>{i.brief}</p>
            <Link to={`${location.pathname}/${i.id}`}>Read more</Link>
          </div>
        ))}
        {articlesData.length == 0 && <span>Loading...</span>}
      </Stack>
    </BasicPage>
  );
}
