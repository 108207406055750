import React, { useRef } from 'react';
import { EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import * as plugins from './draftjsPlugins';
import EditorToolbar from './editorToolbar/editorToolbar';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import * as styles from './articleContentEditor.module.scss';

type ArticleContentEditorProps = {
  editorState: EditorState;
  setEditorState: (value: EditorState) => void;
};

export default function ArticleContentEditor(props: ArticleContentEditorProps) {
  const editorRef = useRef<Editor>(null);

  function handleKeyCommand(command: string, editorState: EditorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      props.setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  return (
    <div
      className={styles.articleEditor}
      onClick={() => editorRef.current?.focus()}
    >
      <EditorToolbar
        editorState={props.editorState}
        setEditorState={props.setEditorState}
      />
      <Editor
        editorState={props.editorState}
        onChange={props.setEditorState}
        plugins={[
          plugins.dndPlugin,
          plugins.dndUploadPlugin,
          plugins.focusPlugin,
          plugins.linkifyPlugin,
          plugins.toolbarPlugin,
          plugins.imagePlugin,
          plugins.videoPlugin,
        ]}
        ref={editorRef}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={getDefaultKeyBinding}
        placeholder="Content"
      />
    </div>
  );
}
