export const enum ArticleType {
  About,
  PastEvent,
  GenocideInformation,
  CommunityNews,
  None,
}

export const enum ShopSortType {
  DateAddedAscending,
  DateAddedDescending,
  PriceAscending,
  PriceDescending,
  AToZ,
}
