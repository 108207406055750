import React, { useState } from 'react';
import QueryBar, { QueryFilters } from './queryBar';
import BookList from './bookList';
import { applyFilters } from './utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BasicPage from '../utils/basicPage';
import strapDB from '../api/strapDB';
import { isLoggedInAsAdmin } from '../api/adminAPI';
import { Button, Icon } from '@mui/material';
import BookCarousel from './mainPage/bookCarousel';
import SectionGrid from './mainPage/sectionGrids';
import * as styles from './libraryPage.module.scss';

export default function Library() {
  const [books, setBooks] = useState<BookJson[]>([]);
  const [sects, setsects] = useState<SectionJson[]>([]);
  const [refetch, setRefetch] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  function onFiltersChanged(filters: QueryFilters) {
    // Filters should already be a record of string to string.
    setSearchParams(filters as Record<string, string>);
  }

  if (refetch) {
    setRefetch(false);
    strapDB.book.fetchAll('useCache').then(setBooks);
    strapDB.section.fetchAll('useCache').then(setsects);
  }
  const filters = queryFiltersFromSearchParams(searchParams);

  function onSelectSection(value: SectionJson) {
    // Scroll before setting search params so it gets to the top of the
    // page quickly rather than scrolling up through a potentially very
    // long list of books.
    window.scrollTo(0, 0);
    setSearchParams({ section: value.name });
  };

  let showbooklist = filters.search || filters.section;

  const adminMode = isLoggedInAsAdmin();

  return (
    <BasicPage className="library" title="Library">
      {adminMode && (
        <Button
          onClick={() => navigate('/library/ingest')}
          className="indexPageAdminPrimaryAction"
          color="primary"
          size="large"
          variant="contained"
          disableRipple
          startIcon={<Icon>add</Icon>}
        >
          Add books
        </Button>
      )}
      <h1>ACCW Library Catalogue</h1>
      <QueryBar filters={filters} onFiltersChanged={onFiltersChanged} />
      <div className="mb-4"></div>
      {showbooklist ? (
        <BookList books={applyFilters(books, filters)} />
      ) : (
        <>
            <div className={styles.pageDiv1}>
              <BookCarousel books={books} section={{'id':3, 'name':'History'}} />
            </div>
            <div className={styles.pageDiv1}>
              <BookCarousel books={books} section={{'id':1, 'name':'Culture'}}  />
            </div>
          </>
      )}
      <div className={styles.pageDiv1}>
      <h3>Sections</h3>
        <SectionGrid sects={sects} section={0} onSelect={onSelectSection} />
      </div>
    </BasicPage>
  );
}

function queryFiltersFromSearchParams(params: URLSearchParams): QueryFilters {
  return {
    search: params.get('search') || undefined,
    section: params.get('section') || undefined,
  };
}
