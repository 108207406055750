import { mockDataOrDefault, shareUrl } from '../utils/functions';

export const defaultGetProductsResp = mockDataOrDefault<ProductJson[]>(
  [],
  [
    {
      id: 1,
      title: 'Test Product',
      description: 'asdajshdkjahlskdjahsda\nnew paragraph',
      imageLinks: JSON.stringify([
        shareUrl('homePage/events/events01A.jpg'),
        shareUrl('homePage/events/events02A.jpg'),
        shareUrl('homePage/events/events03A.jpg'),
      ]),
      price: 1000,
      deliveryCost: 100,
      isOutOfStock: false,
      filters: [1]
    },
    {
      id: 2,
      title: 'Test Product 2',
      description: 'asdajshdkjahlskdjahsda',
      imageLinks: JSON.stringify([shareUrl('homePage/events/events01A.jpg')]),
      price: 2000,
      deliveryCost: 100,
      isOutOfStock: false,
      filters: [1]
    },
    {
      id: 3,
      title: 'Test Product 3',
      description: 'asdajshdkjahlskdjahsda',
      imageLinks: JSON.stringify([shareUrl('homePage/events/events01A.jpg')]),
      price: 2001,
      deliveryCost: 100,
      isOutOfStock: false,
      filters: [1, 3]
    },
    {
      id: 4,
      title: 'Test Product 3 Long Long Title Here',
      description: 'asdajshdkjahlskdjahsda',
      imageLinks: JSON.stringify([shareUrl('homePage/events/events01A.jpg')]),
      price: 4099,
      deliveryCost: 100,
      isOutOfStock: false,
      filters: [4]
    },
    {
      id: 5,
      title: 'Test Product 4',
      description: 'asdajshdkjahlskdjahsda',
      imageLinks: JSON.stringify([shareUrl('homePage/events/events01A.jpg')]),
      price: 4099,
      deliveryCost: 100,
      isOutOfStock: true,
      filters: []
    },
  ],
);
