import { Button } from '@mui/material';
import React from 'react';
import { verifyViewingPageAsAdminOrLogin } from './adminUtils';
import './adminIndexPage.sass';
import BasicPage from '../utils/basicPage';
import { logOutAsAdmin } from '../api/adminAPI';

export default function AdminIndexPage() {
  verifyViewingPageAsAdminOrLogin();

  return (
    <BasicPage className="adminIndexPage" title="Admin Dashboard">
      <h2>Admin Mode: Dashboard</h2>
      <p>
        Pages on the site will now have additional options.
        <br /> Specific separate things may get added here at a later date.
      </p>
      <Button href="/" onClick={logOutAsAdmin} variant="outlined" disableRipple>
        log out
      </Button>
    </BasicPage>
  );
}
