import { mockDataOrDefault } from '../utils/functions';

export const defaultGetOrdersResp = mockDataOrDefault<OrderJson[]>(
  [],
  [
    {
      date: '2022-08-03T09:53',
      email: 'user@mytestemail.com',
      amount: 100,
      fee: 22,
      refunded: 0,
      orderID: 20,
      clientSecret:
        'pi_3LwQmGCWev50zb2R1iD7wZag_secret_zcF9gfaWXvrN63b65DlX624E0',
    },
    {
      date: '2022-10-03T09:53',
      email: 'user@mytestemail.com',
      amount: 200,
      fee: 24,
      orderID: 20,
      refunded: 0,
      clientSecret:
        'pi_3LwQmGCWev50zb2R1iD7wZag_secret_zcF9gfaWXvrN63b65DlX624E0',
    },
    {
      date: '2022-08-23T09:53',
      email: 'user@mytestemail.com',
      amount: 2099,
      refunded: 0,
      fee: 22,
      orderID: 20,
      clientSecret:
        'pi_3LwQmGCWev50zb2R1iD7wZag_secret_zcF9gfaWXvrN63b65DlX624E0',
    },
  ],
);
