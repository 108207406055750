import {
  Autocomplete,
  Button,
  createFilterOptions,
  FilterOptionsState,
  Icon,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyViewingPageAsAdminOrLogin } from '../admin/adminUtils';
import strapDB from '../api/strapDB';
import BasicPage from '../utils/basicPage';
import { useSnackbar } from '../utils/snackbar';
import {
  BookEditDialogCloseReason,
  CreditEditDialog,
  SectionEditDialog,
} from './bookEditDialogs';
import * as styles from './bookEditor.module.scss';

type BookJsonRawOptionalID = Unpacked<
  { id?: number } & Omit<BookJsonRaw, 'id'>
>;

export default function BookEditorPage() {
  verifyViewingPageAsAdminOrLogin();

  const [isLoaded, setIsLoaded] = useState(false);
  const [title, setTitle] = useState('');
  const [titleTranslation, setTitleTranslation] = useState('');
  const [credit, setCredit] = useState<CreditJson | null>(null);
  const [section, setSection] = useState<SectionJson | null>(null);
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const params = useParams();

  let bookID = parseInt(params.bookID || '') || undefined;
  const allowSave = !!(title && credit && section);

  if (!isLoaded) {
    setIsLoaded(true);
    if (bookID) {
      loadBookData(bookID);
    }
  }

  async function loadBookData(bookID: number) {
    // fetch from book not bookRaw to use full CreditJson/SectionJson
    const bookData = await strapDB.book.fetchOne(bookID, 'useCache');
    if (!bookData) {
      snackbar.error(`Invalid book ID: ${bookID}`);
      navigate('/library');
      return;
    }
    setTitle(bookData.title);
    setTitleTranslation(bookData.titleTranslation || '');
    setCredit(bookData.author || null);
    setSection(bookData.section);
  }

  async function onClickSave() {
    const id = await save_Impl();
    if (id) {
      navigate(`/library/book/${id}/edit`, { replace: true });
    }
  }

  async function onClickSaveAndAddAnother() {
    const id = await save_Impl();
    if (id) {
      setTitle('');
      setTitleTranslation('');
      setCredit(null);
      setSection(null);
    }
  }

  function onClickCancel() {
    if (confirm('Are you sure you would like to cancel?')) {
      navigate('/library');
    }
  }

  async function onClickDelete() {
    if (!bookID) throw new Error('invalid state deleting null book');
    if (confirm('Are you sure you want to delete this record?')) {
      const titleCache = title;
      const success = await strapDB.bookRaw.delete(bookID);
      if (success) {
        snackbar.success(`Successfully deleted '${titleCache}'`);
        navigate('/library');
      } else {
        snackbar.error('Failed to delete book');
      }
    }
  }

  async function save_Impl() {
    if (!credit || !section) throw new Error('invalid state');
    const val: BookJsonRawOptionalID = {
      title,
      titleTranslation,
      author: credit.id,
      section: section.id,
    };
    const id =
      bookID === undefined
        ? await strapDB.bookRaw.create(val)
        : (await strapDB.bookRaw.update({ ...val, id: bookID })) && bookID;
    if (id) {
      snackbar.success('Successfully saved book');
    } else {
      snackbar.error('Failed to save book');
    }
    return id;
  }

  return (
    <BasicPage title="Books Ingest">
      <h2>How to add books</h2>
      <ul>
        <li>
          When typing in the title, the database is searched for matches so you
          can avoid adding a duplicate.
        </li>
        <li>
          If the title isn't in English, it may be written in original language
          and an English translation can be written in the Title Translation.
        </li>
        <li>Title Translation can be left blank if not required.</li>
        <li>
          Write the author like 'Surname, First Name' as it is easier to sort in
          the listing page.
        </li>
        <li>
          If there are multiple authors, they can be separated by ; semicolon.
        </li>
        <li>
          To rename or delete an author or section click the edit button next to
          it to open the popup.
        </li>
      </ul>
      <Col className={styles.ingestForm} lg="8">
        <TitleField value={title} onChange={setTitle} />
        <TextField
          value={titleTranslation}
          onChange={e => setTitleTranslation(e.target.value)}
          label="Title Translation"
          variant="standard"
        />
        <AuthorField value={credit} onChange={setCredit} />
        <SectionField value={section} onChange={setSection} />
      </Col>
      <div className={styles.formActions}>
        <Button
          onClick={onClickSave}
          disabled={!allowSave}
          variant="contained"
          disableRipple
        >
          Save
        </Button>
        <Button
          onClick={onClickSaveAndAddAnother}
          disabled={!allowSave}
          variant="contained"
          disableRipple
        >
          Save and add another
        </Button>
        <Button onClick={onClickCancel} variant="text" disableRipple>
          Cancel
        </Button>
        {bookID && (
          <Button onClick={onClickDelete} variant="text" disableRipple>
            Delete
          </Button>
        )}
      </div>
    </BasicPage>
  );
}

type TitleFieldProps = {
  value: string;
  onChange: (value: string) => void;
};

function TitleField(props: TitleFieldProps) {
  const [options, setOptions] = useState<string[]>([]);
  const [refetch, setRefetch] = useState(true);

  if (refetch) {
    setRefetch(false);
    strapDB.bookRaw
      .fetchAll()
      .then(books => setOptions(books.map(book => book.title)));
  }

  return (
    <Autocomplete
      value={props.value}
      onChange={(_e, newValue) => props.onChange(newValue)}
      onBlur={e => props.onChange((e.target as HTMLInputElement).value)}
      options={options}
      renderInput={params => {
        return (
          <TextField value="" {...params} label="Title" variant="standard" />
        );
      }}
      freeSolo
      disableClearable
    />
  );
}

const creditFilter = createFilterOptions<CreditJson>();
const sectionFilter = createFilterOptions<SectionJson>();
const ID_CREATE_NEW = -1;

type AuthorFieldProps = {
  value: CreditJson | null;
  onChange: (value: CreditJson | null) => void;
};

function AuthorField(props: AuthorFieldProps) {
  const [options, setOptions] = useState<CreditJson[]>([]);
  const [refetch, setRefetch] = useState(true);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  if (refetch) {
    setRefetch(false);
    strapDB.credit.fetchAll().then(setOptions);
  }

  function onChange(_e: any, newValue: CreditJson) {
    if (newValue.id == ID_CREATE_NEW) {
      const name = newValue.name.split('"')[1];
      if (typeof name !== 'string') throw new Error('invalid parse');
      strapDB.credit.create({ name }).then(newID => {
        if (newID) {
          const newVal: CreditJson = { id: newID, name };
          setOptions([...options, newVal]);
          props.onChange(newVal);
          setRefetch(true);
        } else {
          console.error('failed to add author');
        }
      });
    } else {
      props.onChange(newValue);
    }
  }

  function onClickEdit() {
    if (props.value) {
      setIsEditDialogOpen(true);
    }
  }

  function onCloseEditDialog(reason: BookEditDialogCloseReason) {
    setIsEditDialogOpen(false);
    if (reason != 'cancelled') {
      setRefetch(true);
    }
  }

  function filterOptions(
    options: CreditJson[],
    params: FilterOptionsState<CreditJson>,
  ) {
    const filtered = creditFilter(options, params);
    // Suggest the creation of a new value
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue == option.name);
    if (inputValue && !isExisting) {
      filtered.push({ id: ID_CREATE_NEW, name: `Add "${inputValue}"` });
    }
    return filtered;
  }

  return (
    <div className={styles.fieldWithEdit}>
      <Autocomplete
        value={props.value!}
        onChange={onChange}
        renderInput={params => {
          return <TextField {...params} label="Author" variant="standard" />;
        }}
        options={options}
        filterOptions={filterOptions}
        getOptionLabel={option => option.name}
        selectOnFocus
        clearOnBlur
        disableClearable
      />
      <IconButton onClick={onClickEdit}>
        <Icon>more_horiz</Icon>
      </IconButton>
      <CreditEditDialog
        isOpen={isEditDialogOpen}
        initialValue={props.value}
        onChange={props.onChange}
        onClose={onCloseEditDialog}
      />
    </div>
  );
}

type SectionFieldProps = {
  value: SectionJson | null;
  onChange: (val: SectionJson | null) => void;
};

function SectionField(props: SectionFieldProps) {
  const [options, setOptions] = useState<SectionJson[]>([]);
  const [refetch, setRefetch] = useState(true);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  if (refetch) {
    setRefetch(false);
    strapDB.section.fetchAll().then(setOptions);
  }

  function onChange(_e: any, newValue: SectionJson) {
    if (newValue.id == ID_CREATE_NEW) {
      const name = newValue.name.split('"')[1];
      if (typeof name !== 'string') throw new Error('invalid parse');
      strapDB.section.create({ name }).then(newID => {
        if (newID) {
          const newVal: SectionJson = { id: newID, name };
          setOptions([...options, newVal]);
          props.onChange(newVal);
          setRefetch(true);
        } else {
          console.error('failed to add section');
        }
      });
    } else {
      props.onChange(newValue);
    }
  }

  function onClickEdit() {
    if (props.value) {
      setIsEditDialogOpen(true);
    }
  }

  function onCloseEditDialog(reason: BookEditDialogCloseReason) {
    setIsEditDialogOpen(false);
    if (reason != 'cancelled') {
      setRefetch(true);
    }
  }

  function filterOptions(
    options: SectionJson[],
    params: FilterOptionsState<SectionJson>,
  ) {
    const filtered = sectionFilter(options, params);
    // Suggest the creation of a new value
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue == option.name);
    if (inputValue && !isExisting) {
      filtered.push({ id: ID_CREATE_NEW, name: `Add "${inputValue}"` });
    }
    return filtered;
  }

  return (
    <div className={styles.fieldWithEdit}>
      <Autocomplete
        value={props.value!}
        onChange={onChange}
        renderInput={params => {
          return <TextField {...params} label="Section" variant="standard" />;
        }}
        options={options}
        filterOptions={filterOptions}
        getOptionLabel={option => option.name}
        selectOnFocus
        clearOnBlur
        disableClearable
      />
      <IconButton onClick={onClickEdit}>
        <Icon>more_horiz</Icon>
      </IconButton>
      <SectionEditDialog
        isOpen={isEditDialogOpen}
        initialValue={props.value}
        onChange={props.onChange}
        onClose={onCloseEditDialog}
      />
    </div>
  );
}
