import { useState, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import strapDB from './strapDB';

const g_CART_CACHE_KEY = 'cart';

export type CartItem = {
  productID: number;
  quantity: number;
};

export type CartItemExpanded = {
  product: ProductJson | undefined;
  quantity: number;
};

type CartImpl<ItemTYpe> = ItemTYpe[];

export type Cart = CartImpl<CartItem>;
export type CartExpanded = CartImpl<CartItemExpanded>;

export class CartHandler {
  constructor(
    private getCart: () => Cart,
    private setCart: (x: Cart) => void,
  ) {}

  /** readonly view of cart items */
  get items() {
    return this.getCart() as Readonly<Cart>;
  }

  addProduct(productID: number) {
    const cart = this.getCart();
    cart.push({ productID, quantity: 1 });
    this.setCart(cart);
  }

  includes(productID: number) {
    return this.items.findIndex(x => x.productID == productID) != -1;
  }

  /** product ID translated to product object */
  async getCartExpanded() {
    return await Promise.all(
      this.items.map(
        async cartItem =>
          ({
            quantity: cartItem.quantity,
            product: await strapDB.product.fetchOne(
              cartItem.productID,
              'useCache',
            ),
          } as CartItemExpanded),
      ),
    );
  }

  setQuantity(index: number, newQuantity: number) {
    const cart = this.getCart();
    const cartItem = cart[index];
    if (!cartItem) throw new Error('Invalid cart index ' + index);
    cartItem.quantity = newQuantity;
    if (cartItem.quantity == 0) {
      this.removeItem(index);
    }
    this.setCart(cart);
  }

  removeItem(index: number) {
    const cart = this.getCart();
    if (cart.splice(index, 1).length == 0)
      throw new Error('Invalid cart index (nothing was removed): ' + index);
    this.setCart(cart);
  }

  findItem(productID: number) {
    return this.items.find(x => x.productID == productID);
  }

  async getProductTotal(item: CartItem) {
    const product = await strapDB.product.fetchOne(item.productID, 'useCache');
    return product ? product.price * item.quantity : 0;
  }

  async getProductsTotal() {
    const productTotals = await Promise.all(
      this.items.map(item => this.getProductTotal(item)),
    );
    return productTotals.reduce((x, y) => x + y, 0);
  }

  async getShippingTotal() {
    return (await this.getCartExpanded()).reduce(
      (x, y) => x + (y.product?.deliveryCost ?? 0),
      0,
    );
  }

  clear() {
    this.setCart([]);
  }
}

export function useCart() {
  const [value, setValue] = useLocalStorage<Cart>(g_CART_CACHE_KEY, []);
  return new CartHandler(() => value, setValue);
}

/** helper for just getting cart total (sometimes all u need!)
 * @deprecated
 */
export function useCartTotal() {
  const [total, setTotal] = useState(0);
  const cart = useCart();
  useEffect(() => {
    cart.getProductsTotal().then(setTotal);
  }, [cart]);
  return total;
}
