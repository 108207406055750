/** @returns a brief version of a text */
export function condenseText(content: string, maxLength = 200) {
  return content.length < maxLength
    ? content
    : content.substring(0, maxLength) + '...';
}

/** @returns string formatted number as short currency, so avoid redundant .00 */
export function formatCompactCurrency(pence: number) {
  const formatter = Intl.NumberFormat('en', {
    currency: 'gbp',
    style: 'currency',
  });
  const asStr = formatter.format(pence / 100);
  return asStr.replace(/\.?0*$/, '');
}

/** format currency to gbp, with input as pence */
export function formatCurrency(pence: number) {
  return Intl.NumberFormat('en', {
    currency: 'gbp',
    style: 'currency',
  }).format(pence / 100);
}

export function formatTimestamp(date: string | Date) {
  date = typeof date == 'string' ? new Date(date) : date;
  return Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(date);
}
