import React, { useState } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import Dropzone from 'react-dropzone';
import { CircularProgress, Icon, IconButton } from '@mui/material';
import { tryCatch } from './functions';
import { uploadFile } from '../api/fileUpload';
import * as styles from './imageGalleryEditor.module.scss';
import { useSnackbar } from './snackbar';

export type ImageGalleryEditorProps = {
  /** list of image urls */
  value: string[];

  /** called when images changed, by upload or reordering */
  onChange: (value: string[]) => void;
};

/**
 * Editor for a list of images.
 *
 * Supports uploading and reordering.
 */
export default function ImageGalleryEditor(props: ImageGalleryEditorProps) {
  function onChange(
    _sourceId: any,
    sourceIndex: number,
    targetIndex: number,
    _targetId: any,
  ) {
    props.onChange(swap(props.value, sourceIndex, targetIndex));
  }

  function onUploaded(newImages: string[]) {
    // add new images to the current ones
    props.onChange([...props.value, ...newImages]);
  }

  function onDeleteCell(imageUrl: string) {
    props.onChange(props.value.filter(x => x != imageUrl));
  }

  const rowHeight = 200;
  const boxesPerRow = 3;
  const gridHeight =
    rowHeight * (1 + Math.floor(props.value.length / boxesPerRow));
  return (
    <GridContextProvider onChange={onChange}>
      <GridDropZone
        style={{ height: `${gridHeight}px` }}
        id="myImageGallery"
        boxesPerRow={boxesPerRow}
        rowHeight={rowHeight}
      >
        {[
          ...props.value.map(imageUrl => (
            <ImageCell key={imageUrl} imageUrl={imageUrl} onDelete={() => onDeleteCell(imageUrl)} />
          )),
          <UploaderCell key={"uploaderCell"} onUploaded={onUploaded} />,
        ]}
      </GridDropZone>
    </GridContextProvider>
  );
}

type ImageCellProps = {
  imageUrl: string;
  onDelete: () => void;
};

function ImageCell(props: ImageCellProps) {
  return (
    <GridItem>
      <div
        className="w-100 h-100"
        style={{ background: `url('${props.imageUrl}') center / cover` }}
      >
        <IconButton
          className="m-1"
          size="large"
          onClick={e => void (e.stopPropagation(), props.onDelete())}
          onMouseDown={e => e.stopPropagation()}
          onTouchMove={e => tryCatch(e.stopPropagation)}
        >
          <Icon>delete</Icon>
        </IconButton>
      </div>
    </GridItem>
  );
}

type UploaderCellProps = {
  onUploaded: (newImages: string[]) => void;
};

function UploaderCell(props: UploaderCellProps) {
  const [isUploading, setIsUploading] = useState(false);
  const snackbar = useSnackbar();

  async function onSelectFiles(files: File[]) {
    if (files) {
      setIsUploading(true);
      const uploadResponses = await Promise.all(files.map(uploadFile));
      setIsUploading(false);
      if (uploadResponses.some(x => !x.success)) {
        snackbar.error('Failed to upload image(s)');
      }
      // @ts-ignore
      const newImages = uploadResponses.filter(x => x.success).map(x => x.url);
      newImages && props.onUploaded(newImages);
    }
  }

  return (
    <GridItem
      key="upload"
      onClick={e => e.preventDefault()}
      onMouseDown={e => e.preventDefault()}
      onTouchMove={e => tryCatch(e.preventDefault)}
    >
      {isUploading ? (
        <div className={styles.uploaderItem}>
          <CircularProgress
            className={styles.spinner}
            size={32}
            color="inherit"
          />
          Uploading...
        </div>
      ) : (
        <Dropzone
          onDrop={onSelectFiles}
          accept={{
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/webp': ['.webp'],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={styles.uploaderItem}>
              <input {...getInputProps()} />
              <Icon fontSize="large">upload</Icon>
              Upload Images
            </div>
          )}
        </Dropzone>
      )}
    </GridItem>
  );
}
