import React, { useRef, useState } from 'react';
import * as styles from '../articleContentEditor.module.scss';
import {
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  Popover,
} from '@mui/material';
import { uploadFile } from '../../api/fileUpload';
import { useSnackbar } from '../../utils/snackbar';

type BasicButtonWithURLInputProps = {
  onSubmit: (userPassedURL: string) => void;
  iconName: string;
  acceptedMimeTypes: string;
};

export default function BasicButtonWithURLInputAndUpload(
  props: BasicButtonWithURLInputProps,
) {
  const buttonRef = useRef(null);
  const URLInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const snackbar = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  if (isOpen && URLInputRef.current) {
    URLInputRef.current.focus();
  }

  function onClickURLDone() {
    setIsOpen(false);
    const userPassedURL = URLInputRef.current!.value;
    if (userPassedURL) {
      props.onSubmit(userPassedURL);
    }
  }

  async function onClickUploadDone() {
    const file = fileInputRef?.current?.files?.[0] ?? undefined;
    setIsUploading(true);
    if (file) {
      const resp = await uploadFile(file);
      if (resp.success) {
        props.onSubmit(resp.url);
      } else {
        console.error('failed to upload file error:', resp.error);
        snackbar.error('Failed to upload file');
      }
    }
    setTimeout(() => setIsUploading(false), 100);
    setIsOpen(false);
  }

  return (
    <>
      <IconButton
        size="small"
        ref={buttonRef}
        onMouseUp={() => setIsOpen(true)}
      >
        <Icon>{props.iconName}</Icon>
      </IconButton>
      <Popover
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className={styles.toolbarButtonPopover}>
          <form onSubmit={e => (e.preventDefault(), onClickURLDone())}>
            <input ref={URLInputRef} placeholder="Paste URL" />{' '}
          </form>
          <IconButton size="small" onMouseDown={onClickURLDone}>
            <Icon>done</Icon>
          </IconButton>
        </div>
        <Divider variant="middle">OR</Divider>
        <div className={styles.toolbarButtonPopover}>
          <input
            ref={fileInputRef}
            accept={props.acceptedMimeTypes}
            type="file"
          />
          {isUploading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <IconButton size="small" onMouseDown={onClickUploadDone}>
              <Icon>done</Icon>
            </IconButton>
          )}
        </div>
      </Popover>
    </>
  );
}
