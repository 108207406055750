import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import * as styles from './eventsPage.module.scss';
import strapDB from '../api/strapDB';

export type EventOptionalID = Unpacked<{ id?: number } & Omit<EventJson, 'id'>>;

type EventEditorDialogProps = {
  /**
   * If defined dialog is open.
   * If ID is undefined, it is creating.
   * Otherwise updating.
   */
  eventData?: EventOptionalID;

  /** Called when data is changed - the dialog doesn't own its own form data. */
  onChange: (eventData?: EventOptionalID) => void;

  /**
   * @param data If undefined dialog was cancelled.
   * If ID is undefined user wants to create a new event.
   * Otherwise it's an update to the existing event.
   */
  onClose: (data?: EventOptionalID | 'deleted') => void;

  isOpen: boolean;
};

export default function EventEditorDialog(props: EventEditorDialogProps) {
  const mode = props.eventData?.id ? 'update' : 'create';
  const canSave =
    props.eventData?.title &&
    props.eventData?.date &&
    props.eventData?.description;

  function onChangeField(change: Partial<EventOptionalID>) {
    if (!props.eventData) throw new Error('invalid state event data invalid');
    props.onChange({ ...props.eventData, ...change });
  }

  function onCloseDialog() {
    props.onClose();
  }

  function onClickSave() {
    props.onClose(props.eventData);
  }

  function onClickSaveAsNew() {
    if (!props.eventData?.id) throw new Error('invalid id or eventdata');
    const eventData = { ...props.eventData };
    delete eventData.id;
    props.onClose(eventData);
  }

  function onClickCancel() {
    props.onClose();
  }

  async function onClickDelete() {
    const id = props.eventData?.id;
    if (!id) throw new Error('invalid state null id to delete');
    if (confirm('Are you sure you want to delete this event?')) {
      await strapDB.event.delete(id);
      props.onClose('deleted');
    }
  }

  return (
    <Dialog fullWidth onClose={onCloseDialog} open={props.isOpen}>
      <DialogContent>
        <form className={styles.editorDialogForm}>
          <h4 className={styles.eventTitle}>
            <input
              value={props.eventData?.title ?? ''}
              onChange={e => onChangeField({ title: e.target.value })}
              className="w-100"
              placeholder="Title"
            />
          </h4>
          <input
            value={props.eventData?.date ?? ''}
            onChange={e => onChangeField({ date: e.target.value })}
            className={styles.eventDate}
            type="datetime-local"
          />
          <textarea
            value={props.eventData?.description ?? ''}
            onChange={e => onChangeField({ description: e.target.value })}
            className={styles.eventDescription}
            placeholder="Description"
            rows={3}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClickSave}
          disabled={!canSave}
          variant="contained"
          disableRipple
        >
          Save
        </Button>
        {mode == 'update' && (
          <Button
            onClick={onClickSaveAsNew}
            disabled={!canSave}
            variant="contained"
            disableRipple
          >
            save as new
          </Button>
        )}
        <Button onClick={onClickCancel} disableRipple>
          Cancel
        </Button>
        {mode == 'update' && (
          <Button onClick={onClickDelete} disableRipple>
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
