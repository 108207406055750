.eventCard {
  border-top: 4px solid;
  border-right: 4px solid;
  --eventAccentHue: 190;  // override per component random
  border-top-color: hsl(var(--eventAccentHue), 40%, 75%);
  border-right-color: hsl(var(--eventAccentHue), 40%, 55%);
  border-top-right-radius: 20px!important;
  border-bottom-right-radius: 10px!important;
  border-top-left-radius: 10px!important;
}

.eventTitle {
  margin-bottom: 0;
}

.eventDate {
  margin-bottom: 0.5rem;
}

.eventDescription {
  margin-bottom: 0;
  white-space: pre-wrap;
}

.editorDialogForm {
  & > textarea {
    width: 100%;
  }
}

.pastEventsYearWidget {
  height: 100%;
  display: flex;
  align-items: center;
  & fieldset {
    border: none;
  }
}

.pageSectionTitle {
  font-weight: lighter;
  font-size: 1.75em;
  text-align: center;
  margin-bottom: 0.5em;
}

.pastEventsTitle {
  margin-top: 1.5em;
  padding-bottom: 0.25em;
  margin-bottom: 0.25em;
}
