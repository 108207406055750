@use '../utils/styles';

.articleIndexPage {
  & p {
    margin-bottom: 0.2em;
  }
  & a {
    color: inherit;
  }
  & .articleTitleLink {
    text-decoration: none;
  }
  & .articleBrief {
    overflow-wrap: anywhere;
  }
  & .articleListing {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: start;
    & > button {
      flex-grow: 1;
      align-self: stretch;
    }
  }
}
