import classNames from 'classnames';
import React, { ReactNode, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Footer from './footer';
import { isDevMode } from './functions';

type BasicPageProps = {
  children: ReactNode | ReactNode[];
  className?: string;
  fillToEdges?: boolean;
  title: string;
};

export default function BasicPage(props: BasicPageProps) {
  useEffect(() => {
    document.title = `${isDevMode() ? '[DEV] ' : ''}ACCW - ${props.title}`;
  });

  return (
    <>
      <main className={classNames('basicPage', props.className)}>
        {props.fillToEdges && props.children}
        {!props.fillToEdges && (
          <Container className="mt-4">{props.children}</Container>
        )}
      </main>
      <Footer />
    </>
  );
}
