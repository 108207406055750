import { mockDataOrDefault } from '../utils/functions';

export const defaultGetDeliveryOptionsResp = mockDataOrDefault<
  DeliveryOptionJson[]
>(
  [],
  [
    { id: 1, name: 'Ordinary', cost: 300 },
    { id: 2, name: 'Special', cost: 700 },
    { id: 3, name: 'Deluxe', cost: 14500 },
  ],
);
