import React, { useEffect, useState } from 'react';
import EventCard from './eventCard';
import BasicPage from '../utils/basicPage';
import strapDB from '../api/strapDB';
import { isLoggedInAsAdmin } from '../api/adminAPI';
import { Button, Icon, MenuItem, Select } from '@mui/material';
import * as styles from './eventsPage.module.scss';
import EventEditorDialog, { EventOptionalID } from './eventEditorDialog';
import { currentDateTimeString, splitPastUpcomingEvents } from '../utils/functions';
import { useSnackbar } from '../utils/snackbar';
import NavbarAdornment from '../utils/navbarAdornment';
import ArticleGroupViewer from '../articles/articleGroupViewer';
import { ArticleType } from '../utils/enums';
import classNames from 'classnames';
import { AdminBigButton } from '../utils/adminComponents';
import AddPastEventDialog, {
  useAddPastEventDialogHandle,
} from './addPastEventDialog';
import { useNavigate } from 'react-router-dom';

export default function EventsPage() {
  const [events, setEvents] = useState<EventJson[]>([]);
  const [years, setYears] = useState<number[]>([]);
  const [activeYear, setActiveYear] = useState<number>();
  const [jumpToYear, setJumpToYear] = useState<number>();
  const [editDialogEventData, setEditDialogEventData] =
    useState<EventOptionalID>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const addPastEventDialog = useAddPastEventDialogHandle();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [forceReload, setForceReload] = useState(true);

  if (forceReload) {
    setForceReload(false);
    strapDB.event.fetchAll('useCache').then(setEvents);
    return <></>;
  }

  const { upcoming, past } = splitPastUpcomingEvents(events);
  const adminMode = isLoggedInAsAdmin();

  function onClickCreateEvent() {
    setIsEditDialogOpen(true);
    setEditDialogEventData({
      title: '',
      description: '',
      date: currentDateTimeString(),
    });
  }

  function onCloseEditDialog(eventData?: EventOptionalID | 'deleted') {
    if (eventData == 'deleted') {
      reloadEventsList();
      snackbar.success('Deleted event');
    } else if (eventData) {
      const fn = strapDB.event[eventData.id ? 'update' : 'create'];
      fn(eventData as EventJson).then(result => {
        if (result) {
          snackbar.success('Saved event');
          reloadEventsList();
        } else {
          snackbar.error('Failed to save event');
        }
      });
    }
    setIsEditDialogOpen(false);
  }

  function onEditEvent(event: EventJson) {
    setIsEditDialogOpen(true);
    setEditDialogEventData(event);
  }

  async function onDeleteEvent(event: EventJson) {
    if (confirm('Are you sure you want to delete this event?')) {
      await strapDB.event.delete(event.id);
      reloadEventsList();
    }
  }

  function reloadEventsList() {
    setForceReload(true);
  }

  function onClickAddPastEvent() {
    addPastEventDialog.open();
  }

  function onAddPastEventContinue(eventID: number) {
    navigate(`/events/new?eventId=${eventID}`)
  }

  return (
    <>
      <BasicPage title="Events">
        <h2 className={styles.pageSectionTitle}>Upcoming Events</h2>
        {adminMode && (
          <>
            <AdminBigButton
              onClick={onClickCreateEvent}
              label="add upcoming event"
            />
            <EventEditorDialog
              eventData={editDialogEventData}
              onChange={setEditDialogEventData}
              isOpen={isEditDialogOpen}
              onClose={onCloseEditDialog}
            />
          </>
        )}
        <EventCardList
          onEdit={onEditEvent}
          onDelete={onDeleteEvent}
          events={upcoming}
        />
        <h2
          className={classNames(
            styles.pageSectionTitle,
            styles.pastEventsTitle,
          )}
        >
          Past Events
        </h2>
        <PastEventYearWidget
          years={years}
          value={activeYear}
          onChange={setJumpToYear}
        />
        {adminMode && (
          <>
            <AdminBigButton
              onClick={onClickAddPastEvent}
              label="add past event"
            />
            <AddPastEventDialog
              handle={addPastEventDialog}
              onContinue={onAddPastEventContinue}
            />
          </>
        )}
        <ArticleGroupViewer
          articleType={ArticleType.PastEvent}
          onYearsRetrieved={setYears}
          onScrolledToYear={setActiveYear}
          jumpToYear={jumpToYear}
        />
      </BasicPage>
    </>
  );
}

type EventMonthGroup = {
  id: string;
  monthName: string;
  events: EventJson[];
};

type PastEventYearWidgetProps = {
  years: number[];
  value: number | undefined;
  onChange: (value: number) => void;
};

function PastEventYearWidget(props: PastEventYearWidgetProps) {
  useEffect(() => {
    if (props.years.length) props.onChange(props.years[0]!);
  }, [props.years]);

  return (
    <NavbarAdornment variant="sticky" position="end">
      <div className={styles.pastEventsYearWidget}>
        <Select
          size="small"
          variant="outlined"
          value={props.value ?? ''} // empty string for no selection
          onChange={e => props.onChange(e.target.value as number)}
        >
          {props.years.map(i => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </div>
    </NavbarAdornment>
  );
}

/** @deprecated */
function toMonthGroups(events: EventJson[]) {
  const retVal: EventMonthGroup[] = [];
  for (const event of events) {
    const date = new Date(event.date);
    const dateFormatter = Intl.DateTimeFormat('en-GB', {
      month: 'long',
      year: 'numeric',
    });
    console.log(dateFormatter.format(date));
    // date.getFullYear()
    // date.
  }
  return retVal;
}

type EventCardListProps = {
  events: EventJson[];
  onEdit: (event: EventJson) => void;
  onDelete: (event: EventJson) => void;
};

function EventCardList(props: EventCardListProps) {
  const showMoreLimit = 2;
  const hasMoreToShow = props.events.length > showMoreLimit;
  const [showMore, setShowMore] = useState(false);

  const shownEvents = props.events.filter(
    (_, i) => showMore || i < showMoreLimit,
  );

  function onClickShowMore() {
    setShowMore(true);
  }

  return (
    <div className="d-flex flex-column" style={{ gap: '1em' }}>
      {shownEvents.map(event => (
        <EventCard
          key={event.id}
          event={event}
          onEdit={() => props.onEdit(event)}
          onDelete={() => props.onDelete(event)}
        />
      ))}
      {props.events.length == 0 && <span>Loading...</span>}
      {!showMore && hasMoreToShow && (
        <Button onClick={onClickShowMore}>Show More</Button>
      )}
    </div>
  );
}
