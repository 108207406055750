import {
  Drawer,
  Icon,
  IconButton,
  Popper,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import React, { MutableRefObject, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIsLoggedInAsAdmin } from '../admin/adminUtils';
import { logOutAsAdmin } from '../api/adminAPI';
import './navBar.sass';

const linksData: LinkTabProps[] = [
  { label: 'Home', href: '/' },
  // {
  //   label: 'Info',
  //   href: '/articles',
  //   // children: [
  //   //   { label: 'About', href: '/articles#' },
  //   //   { label: 'Past Events', href: '/articles?category=Past%20Events' },
  //   //   { label: 'Genocide Information', href: '/articles/22' },
  //   // ],
  // },
  { label: 'About', href: '/about' },
  // { label: 'Past Events', href: '/past-events' },
  { label: 'Events', href: '/events' },
  { label: 'Genocide Information', href: '/genocide-information' },
  { label: 'Library', href: '/library' },
  { label: 'Shop', href: '/shop' },
];

/** Pages that will not show a navbar. */
const blacklistPages = ['/admin/login'];

export default function NavBar() {
  const location = useLocation();
  const isMobileSize = useMediaQuery('(max-width: 822px)');

  // Show articles active even if selected a specific article eg: /x/y/z => /x
  const locationFirstPath = '/' + location.pathname.split('/')[1];
  const matchesTab = !!linksData.find(x => x.href == locationFirstPath);
  const showNavbar = !blacklistPages.includes(location.pathname);
  const tabValue = matchesTab && '/' + location.pathname.split('/')[1];

  if (!showNavbar) return <></>;
  if (isMobileSize) {
    return <MobileNavbar tabValue={tabValue} />;
  }
  return <DesktopNavbar tabValue={tabValue} />;
}

type LinkTabProps = {
  label: string;
  href: string;
  children?: LinkTabProps[];
};

type PlatformNavbarProps = {
  tabValue: string | false;
};

type HoverTabState = {
  element: HTMLAnchorElement | undefined;
  usingSubmenu: boolean;
};

function DesktopNavbar(props: PlatformNavbarProps) {
  const [hoverTab, setHoverTab] = useState<HoverTabState>(nullHoverTabState());

  function onClickTab() {
    setHoverTab(nullHoverTabState());
  }

  return (
    <nav>
      <div className="navDesktop">
        <HomeStandaloneLink />
        <Tabs value={props.tabValue} centered>
          {linksData.map(i => linkTab(i, onClickTab, hoverTab, setHoverTab))}
        </Tabs>
        <Popper
          className="navPopper"
          open={!!hoverTab.element}
          anchorEl={hoverTab.element}
          placement="bottom"
          modifiers={[{ name: 'offset', options: { offset: [0, -4] } }]}
          onMouseEnter={() => setHoverTab({ ...hoverTab, usingSubmenu: true })}
          onMouseLeave={() => setHoverTab(nullHoverTabState())}
        >
          {/* Hardcoded to index 1 for INFO submenu */}
          {linksData[1]!.children?.map(i => (
            <Link onClick={onClickTab} key={i.href} to={i.href}>
              {i.label}
            </Link>
          ))}
        </Popper>
      </div>
    </nav>
  );
}

function MobileNavbar(props: PlatformNavbarProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isInfoExpanded, setIsInfoExpanded] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => (setIsDrawerOpen(false), setIsInfoExpanded(false));
  const closeDrawer2 = () => void (isInfoExpanded || closeDrawer());

  const myLinksTab = isInfoExpanded
    ? [
        ...linksData.slice(0, 2),
        ...linksData[1]!.children!,
        ...linksData.slice(3),
      ]
    : linksData;

  return (
    <nav className="navMobile">
      <IconButton onClick={openDrawer}>
        <Icon>menu</Icon>
      </IconButton>
      <HomeStandaloneLink />
      <Drawer open={isDrawerOpen} onClose={closeDrawer}>
        <div className="navBarDrawer">
          <Tabs
            value={props.tabValue}
            onChange={closeDrawer}
            orientation="vertical"
          >
            {myLinksTab.map(i =>
              linkTab(i, closeDrawer2, undefined, undefined, () =>
                setIsInfoExpanded(!isInfoExpanded),
              ),
            )}
          </Tabs>
        </div>
      </Drawer>
    </nav>
  );
}

function linkTab(
  props: LinkTabProps,
  onClick?: () => void,
  hoverTab?: HoverTabState,
  setHoverTab?: (value: HoverTabState) => void,
  onClickExpand?: () => void,
) {
  let myTabRef: MutableRefObject<HTMLAnchorElement | undefined> | undefined;
  if (hoverTab) {
    myTabRef = useRef<HTMLAnchorElement>();
  }

  function onMouseOver() {
    if (props.children) {
      if (setHoverTab) {
        setHoverTab({ ...hoverTab!, element: myTabRef!.current });
      } else if (onClickExpand) {
        onClickExpand();
      }
    }
  }

  function onMouseOut() {
    if (props.children && setHoverTab) {
      if (!hoverTab) throw new Error('Invalid state - missing hoverTab');
      if (!hoverTab.usingSubmenu) {
        setHoverTab(nullHoverTabState());
      }
    }
  }

  return (
    <Tab
      ref={
        hoverTab
          ? (instance: HTMLAnchorElement) => {
              myTabRef!.current = instance;
            }
          : undefined
      }
      key={props.href}
      label={
        <div>
          <span>{props.label}</span>
          {props.children && (
            <span
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onMouseOver();
              }}
            >
              ◢
            </span>
          )}
        </div>
      }
      onClick={onClick}
      value={props.href}
      to={props.href}
      component={Link}
      onMouseOver={hoverTab ? onMouseOver : undefined}
      onMouseOut={onMouseOut}
      disableTouchRipple
    />
  );
}

function HomeStandaloneLink() {
  const adminMode = useIsLoggedInAsAdmin();
  return (
    <div>
      <Link className="homeStandaloneLink" to="/">
        Armenian Community Centre (W)
      </Link>
      {adminMode && (
        <>
          &nbsp;
          <a href="">
            <IconButton
              size="small"
              title="You are currently editing the site in admin mode. Click here to log out. "
              onClick={logOutAsAdmin}
            >
              <Icon color="info">badge</Icon>
            </IconButton>
          </a>
        </>
      )}
    </div>
  );
}

function nullHoverTabState(): HoverTabState {
  return { element: undefined, usingSubmenu: false };
}
