$borderColor: rgb(118, 118, 118);

.articleEditor {
  border: 1px inset $borderColor;
  padding: 4px;
  margin-bottom: 1em;
  :global(.public-DraftEditor-content) {
    min-height: 50vh;
  }
}

.toolbar {
  z-index: 100;
  margin: 0.3em;
  position: sticky;
  top: 90px;
  & .toolbarInner {
    display: flex;
    direction: row;
    align-items: center;
  }
}

.toolbarButtonPopover {
  display: flex;
  align-items: center;
  padding: 0.5em;
  width: 300px;
  height: 50px;
  & > :first-child {
    flex-grow: 1;
  }
  & input {
    width: 100%;
  }
}
