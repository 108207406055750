.form {
  display: block;
  & h5 {
    margin: 1rem 0 0.5rem 0;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1em;
}