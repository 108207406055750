@use '../utils/styles.sass';

.summaryTable {
  display: grid;
  grid-template-columns: max-content min-content;
  margin-top: 2em;
  justify-content: end;
  text-align: end;
  & > :nth-of-type(odd) {
    padding: 0 1ch;
  }
  & > :nth-of-type(even) {
    padding-right: 1ch;
  }
  & > hr {
    margin: 2px 0;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1em;
  margin-top: 1.5em;
}

.stripeCardInput {
  margin-top: 1em;
  width: 100%;
  padding: 2px 4px;
  border-bottom: 1px solid #949494;
  transform: border 0.2s;
  &:focus {
    border-bottom: 3px solid styles.$primary;
  }
}

.loadingScreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    padding: 2em 3em;
    border-radius: 4px;
  }
}
