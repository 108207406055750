import React from 'react';
import { Link } from 'react-router-dom';
import * as styles from './aboutPage.module.scss';
import BasicPage from '../utils/basicPage';

export default function AboutPage() {
  return (
    <BasicPage title="About">
      <div className={styles.header1}>About Page</div>
      <div className={styles.newsSection}>
        <Link to="/about/communityNews" className={styles.gridButton}>
          Community News
        </Link>
        <Link to="/about/12" className={styles.gridButton}>
          What The Press Say
        </Link>
        <Link to="/about/11" className={styles.gridButton}>
          About Armenians
        </Link>
        <Link to="/about/3" className={styles.gridButton}>
          Urartu and the Armenian Language
        </Link>
        <Link to="/about/1" className={styles.gridButton}>
          Armenian Community Center (W)
        </Link>
      </div>
    </BasicPage>
  );
}
