.productCard {
}

.productCardContent {
  display: flex !important;
  flex-direction: column;
}

.title {
  margin: 0.3em 0.5em 0.7em 0.5em;
  font-weight: 600;
  align-self: start;
}

.priceTag {
  align-self: end;
  background: white;
  z-index: 0;
  margin: 2px 2px -2ch 2px;
  &.outOfStock {
    & > .priceTag2 {
      text-decoration: line-through;
    }
    display: flex;
    align-items: center;
  }
}
