import React, { useState } from 'react';
import { TextField } from '@mui/material';

type SearchBoxProps = {
  onSubmit: (value: string) => void;
};

export default function SearchBox(props: SearchBoxProps) {
  const [value, setValue] = useState('');

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (value) {
      props.onSubmit(value);
      setValue('');
    }
  };

  return (
    <form onSubmit={onFormSubmit}>
      <TextField
        size="small"
        variant="outlined"
        label="Search"
        id="search-box"
        value={value}
        onChange={e => setValue(e.target.value)}
        onBlur={onFormSubmit}
      />
    </form>
  );
}
