import {
  Button,
  Divider,
  Icon,
  IconButton,
  Skeleton,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CartExpanded, CartItemExpanded, useCart } from '../api/cartAPI';
import { resizeImage } from '../api/resize/resizeFront';
import BasicPage from '../utils/basicPage';
import CheckoutStepper from './checkoutStepper';
import * as styles from './cartPage.module.scss';
import { formatCompactCurrency, formatCurrency } from '../utils/stringUtils';
import { Col, Row } from 'react-bootstrap';

export default function CartPage() {
  const navigate = useNavigate();
  const cart = useCart();
  const [items, setItems] = useState<CartExpanded>([]);
  const [thumbs, setThumbs] = useState<string[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    cart.getProductsTotal().then(setTotal);
  }, [cart]);

  useEffect(() => {
    if (!isLoaded || cart.items.length != items.length) {
      setIsLoaded(true);
      setThumbs([]); // reset thumbs!
      cart.getCartExpanded().then(expandedItems => {
        // filter out products that maybe got deleted while operating cart?
        setItems(expandedItems.filter(x => x.product));
      });
    }
  }, [cart]);

  // ensure quantities are up to date if didn't reload cart
  items
    .map(x => [x, cart.findItem(x.product!.id!)])
    .forEach(([x, y]) => (x!.quantity = y?.quantity ?? 0));

  useEffect(() => {
    if (thumbs.length != items.length) {
      setThumbs(
        items
          .map(x => (JSON.parse(x.product!.imageLinks) as string[])[0]!)
          .map(fullImage => resizeImage(fullImage, 100, 100)),
      );
    }
  }, [items]);
  const itemsAndThumbs = items.map((item, i) => ({ item, thumb: thumbs[i]! }));
  return (
    <BasicPage title="Cart">
      <CheckoutStepper currentStep={0} />
      <Row>
        <Col lg={2} sm={1} />
        <Col>
          {!isLoaded ? (
            <>
              <Skeleton height={100} />
              <Skeleton height={100} />
              <Skeleton height={100} />
            </>
          ) : total == 0 ? (
            <p>Your cart is empty</p>
          ) : (
            <Stack divider={<Divider variant="inset" />} gap={2}>
              {itemsAndThumbs.map(({ item, thumb }, i) => (
                <Item key={i} itemIndex={i} item={item} thumb={thumb} />
              ))}
            </Stack>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={10} sm={11} className={styles.actionBar}>
          <span>Total: {formatCurrency(total)}</span>
          <div>
            <Button disableRipple onClick={() => navigate('/shop')}>
              Back to shop
            </Button>
            <Button
              style={{ marginLeft: '1em' }}
              disableRipple
              variant="contained"
              onClick={() => navigate('/shop/shipping')}
              disabled={total == 0}
            >
              Continue
            </Button>
          </div>
        </Col>
      </Row>
    </BasicPage>
  );
}

type ItemProps = {
  item: CartItemExpanded;
  thumb: string;
  itemIndex: number;
};

function Item(props: ItemProps) {
  const { thumb, item } = props;
  const cart = useCart();

  function onClickQuantityChange(diff: number) {
    cart.setQuantity(props.itemIndex, item.quantity + diff);
  }
  function onClickRemove() {
    cart.removeItem(props.itemIndex);
  }

  return (
    <div className={styles.item}>
      <Link to={`/shop/product/${item.product!.id}`}>
        <img src={thumb} width={100} height={100} />
      </Link>
      <div className={styles.itemInfo}>
        <Link className={styles.title} to={`/shop/product/${item.product!.id}`}>
          {item.product!.title} ({formatCompactCurrency(item.product!.price)})
        </Link>
        <span className={styles.quantityRoot}>
          Quantity:
          <IconButton
            size="small"
            disabled={item.quantity == 1}
            disableRipple
            onClick={() => onClickQuantityChange(-1)}
          >
            <Icon>remove</Icon>
          </IconButton>
          {item.quantity}
          <IconButton
            size="small"
            disableRipple
            onClick={() => onClickQuantityChange(1)}
          >
            <Icon>add</Icon>
          </IconButton>
        </span>
        <Button
          disableRipple
          startIcon={<Icon>delete</Icon>}
          color="warning"
          size="small"
          onClick={onClickRemove}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}
