.innerRoot {
  display: flex;
  flex-direction: column;
  & > .logo {
    align-self: center;
    width: 200px;
    margin-bottom: 1em;
    @media (min-width: 992px) {
      margin-bottom: -60px;
    }
  }
}

.productGrid {
  // display: grid;
  gap: 1em;
  // grid-template-columns: repeat(4, auto);
}

.productCardWrapper {
  padding: 0.5em;
}

.shopHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  padding-top: 5px;
}

.searchBar {
  margin-left: 10px;
  width: 100%;
  max-width: 300px;
  @media screen and (max-width: 574px) {
    width: calc(100% - 150px);
  }
}

form:has(.searchBar) {
  margin-right: 1ch;
  @media screen and (max-width: 574px) {
    margin-right: 100vw; // hide other icons to the right
    width: 100%;
  }
}

.checkboxListWrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1em;
}

.checkboxList {
  & > h5 {
    margin: 0;
    display: flex;  // for some reason required for padding??
    & .filterReset {
      padding: 0;
    }
  }
  & > div {
    margin-left: 0.5em;
    & :global(.MuiCheckbox-root) {
      padding: 6px;
    }
  }
}

.cartWidget {
  height: 100%;
  cursor: pointer;
  display: flex;
  padding: 0.5em 1em;
  align-items: center;
  gap: 1em;
  justify-content: end;
}
