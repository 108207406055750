import React from 'react';
import { Container } from 'react-bootstrap';
import {
  useIsLoggedInAsAdmin,
  useSwitchToAdminMode,
} from '../admin/adminUtils';
import './footer.sass';

export default function Footer() {
  const adminMode = useIsLoggedInAsAdmin();
  const switchToAdminMode = useSwitchToAdminMode();

  function onClickSwitchToAdminMode(e: React.MouseEvent) {
    e.preventDefault();
    switchToAdminMode();
  }

  return (
    <footer>
      <Container>
        Website designed by David Kanekanian
        <br />
        &copy; Copyright 2022 Armenian Community Centre (W)
        <br />
        Registered Charity No. 1195813
        <br />
        {!adminMode && (
          <a href="" onClick={onClickSwitchToAdminMode}>
            Switch to admin mode
          </a>
        )}
      </Container>
    </footer>
  );
}
