import { QueryFilters } from './queryBar';

export function applyFilters(books: BookJson[], filters: QueryFilters) {
  const search = filters.search?.toLowerCase();
  const section = filters.section;
  return books
    .filter(
      book =>
        !search ||
        book.title.toLowerCase().includes(search) ||
        book.titleTranslation?.toLowerCase().includes(search) ||
        book.author?.name.toLowerCase().includes(search) ||
        book.section.name.toLowerCase().includes(search),
    )
    .filter(
      book => !section || (book.section.name == section)
    );
}

/** @deprecated */
export function getInitialFilters() {
  const searchParams = new URLSearchParams(window.location.href.split('?')[1]);
  return {
    search: searchParams.get('search') || undefined,
    tag: searchParams.get('tag') || undefined,
  };
}
