import axios from 'axios';
import { isDevMode, promiseDelay } from '../../utils/functions';

const mockResponse: PostFileUploadResp = {
  success: true,
  url: 'https://wales-armenia.87.org.uk/share/homePage/splash01A.jpg',
};

export async function uploadFile(file: File) {
  if (isDevMode()) {
    await promiseDelay(1000);
    return mockResponse;
  }

  const form = new FormData();
  form.append('file', file);
  const res = await axios.post('/api/fileUpload/fileUploadBack.php', form);
  return res.data as PostFileUploadResp;
}
