import React from 'react';
import PhotoStack, { PhotoStackLayer } from '../utils/photoStack';
import BasicPage from '../utils/basicPage';
import { shareUrl, shareUrlCss } from '../utils/functions';
import { Section, Heading, Brief, Action } from './homePageUtils';
import './homeStyle.sass';

export default function HomePage() {
  return (
    <BasicPage fillToEdges title="Home">
      <Section
        name="landing"
        style={{ backgroundImage: shareUrlCss('homePage/splash01A.jpg') }}
      >
        <Heading>Armenian Community Centre (W)</Heading>
      </Section>
      <Section name="centre">
        <Heading>Centre</Heading>
        <Brief>
          A lovely location in Llandaff, Cardiff with plenty of rooms for
          community activities.
        </Brief>
        <PhotoStack>
          <PhotoStackLayer
            src={shareUrl('homePage/center/outside01A.jpg')}
            wide
            caption="Outside the Centre - Entrance is by the Armenian flag"
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/reception01A.jpg')}
            wide
            caption="Reception"
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/reception02A.jpg')}
            wide
            caption="Reception"
          />
          <PhotoStackLayer src={shareUrl('homePage/center/reception03A.jpg')} />
          <PhotoStackLayer
            src={shareUrl('homePage/center/artExhibition01A.jpg')}
            wide
            caption="Art & Design Exhibition Room"
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/artExhibition02A.jpg')}
            wide
            caption="Art & Design Exhibition Room"
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/childrensClass01A.jpg')}
            caption="Children's Class/Playroom"
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/childrensClass02A.jpg')}
            caption="Children's Class/Playroom"
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/childrensClass03A.jpg')}
            caption="Children's Class/Playroom"
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/artClassroom01A.jpg')}
            caption="Art Classroom"
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/artClassroom02A.jpg')}
            caption="Art Classroom"
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/chapelRoom01A.jpg')}
            caption="Chapel Room"
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/chapelRoom02A.jpg')}
            caption="Chapel Room"
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/chapelRoom03A.jpg')}
            caption="Chapel Room"
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/chapelRoom04A.jpg')}
            caption="Chapel Room"
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/meetingRoom01A.jpg')}
            caption="Meeting Room"
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/center/meetingRoom02A.jpg')}
            caption="Meeting Room"
            wide
          />
        </PhotoStack>
        <Action href="/about/1">learn more</Action>
      </Section>
      <Section name="events">
        <Heading>Events</Heading>
        <Brief>
          Twice a month we host a coffee meet and kids Armenian school, and
          bigger gatherings occasionally.
        </Brief>
        <PhotoStack>
          <PhotoStackLayer
            src={shareUrl('homePage/events/events01A.jpg')}
            wide
          />
          <PhotoStackLayer src={shareUrl('homePage/events/events02A.jpg')} />
          <PhotoStackLayer
            src={shareUrl('homePage/events/events03A.jpg')}
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/events/events04A.jpg')}
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/events/events05A.jpg')}
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/events/events06A.jpg')}
            wide
          />
          <PhotoStackLayer
            src={shareUrl('homePage/events/events07A.jpg')}
            wide
          />
        </PhotoStack>
        <Action href="/events">check schedule</Action>
      </Section>
      <Section name="articles" noBottom>
        <Heading>Past Events</Heading>
        <Brief>Discover our history and community activities.</Brief>
        <PhotoStack>
          <PhotoStackLayer src={shareUrl('homePage/articles01A.png')} />
          <PhotoStackLayer src={shareUrl('homePage/articles02A.jpeg')} wide />
        </PhotoStack>
        <Action href="/past-events">learn More</Action>
      </Section>
      <Section name="library" contrast>
        <Heading>Library</Heading>
        <Brief>A unique catalogue of Armenian and Welsh books.</Brief>
        <PhotoStack>
          <PhotoStackLayer src={shareUrl('homePage/library01A.jpg')} wide />
          <PhotoStackLayer src={shareUrl('homePage/library02A.jpg')} />
          <PhotoStackLayer src={shareUrl('homePage/library03A.jpg')} wide />
          <PhotoStackLayer src={shareUrl('homePage/library04A.jpg')} />
        </PhotoStack>
        <Action href="library">view online database</Action>
      </Section>
      <Section name="shop">
        <Heading>Shop</Heading>
        <Brief>
          Specially designed gifts you can buy to support the community.
        </Brief>
        <PhotoStack>
          <PhotoStackLayer src={shareUrl('homePage/shop/hyDesign01A.jpg')} wide />
          <PhotoStackLayer src={shareUrl('homePage/shop/hyDesign02A.jpg')} wide />
          <PhotoStackLayer src={shareUrl('homePage/shop/hyDesign03A.jpg')} wide />
          <PhotoStackLayer src={shareUrl('homePage/shop/hyDesign04A.jpg')} wide />
          <PhotoStackLayer src={shareUrl('homePage/shop/hyDesign05A.jpg')} wide />
          <PhotoStackLayer src={shareUrl('homePage/shop/shop01A.jpg')} />
        </PhotoStack>
        <Action href="shop">see products</Action>
      </Section>
      <Section name="contact" noBottom>
        <Heading>Contact Us</Heading>
        <Brief>
          Email:&nbsp;
          <a href="mailto:info@wales-armenia.org.uk">
            info@wales-armenia.org.uk
          </a>
          <br />
          Address:&nbsp;
          <a target="_blank" href="https://goo.gl/maps/N7o8526ZUYbvrHPo9">
            18d High Street, Cardiff, CF5 2DZ
          </a>
        </Brief>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.0439763147824!2d-3.221241919485483!3d51.49406047973263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486e1b6ae14bffff%3A0x254c02febbec3f6a!2s18d%20High%20St%2C%20Cardiff%20CF5%202DZ!5e0!3m2!1sen!2suk!4v1651954311004!5m2!1sen!2suk"
          style={{ border: '0', width: '100%', height: '60vh' }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Section>
    </BasicPage>
  );
}
