import React from 'react';
import BasicButtonWithURLInputAndUpload from './basicButtonWithURLInputAndUpload';

type ImageButtonProps = {
  onSubmit: (imageURL: string) => void;
};

export default function ImageButton(props: ImageButtonProps) {
  return (
    <BasicButtonWithURLInputAndUpload
      onSubmit={props.onSubmit}
      iconName="image"
      acceptedMimeTypes="image/jpeg,image/png,image/webp"
    />
  );
}
