import { mockDataOrDefault } from '../utils/functions';

export const defaultGetProductFiltersResp = mockDataOrDefault<
  ProductFiltersJson[]
>(
  [],
  [
    { id: 1, name: 'books' },
    { id: 2, name: 'clothing' },
    { id: 3, name: 'jewelry' },
    { id: 4, name: 'accessories' },
  ],
);
