import { isDevMode } from '../../utils/functions';

const mockResponse =
  'https://wales-armenia.87.org.uk/share/resize/bp.4879a7.200x200.png';

/**
 * Resize image to dimensions, attempting to use cache if possible
 * @returns url to new image
 */
export function resizeImage(src: string, x: number, y: number) {
  if (!src || x < 0 || y < 0)
    throw new Error(`invalid argument(s): ${src}, ${x}, ${y}`);

  if (isDevMode()) {
    return mockResponse;
  }

  const params = new URLSearchParams();
  params.set('src', src);
  params.set('x', x.toString());
  params.set('y', y.toString());
  return '/api/resize/resizeBack.php?' + params.toString();
}
