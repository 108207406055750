import axios from 'axios';
import { isDevMode } from './functions';

type Stringable = {
  toString: () => string;
};

type CrudSubmission = Record<string, Stringable>;

type BatchMode = 'batchMode';

export function recordToFormData(record: CrudSubmission) {
  const form = new FormData();
  for (const [key, value] of Object.entries(record)) {
    if (value instanceof Array) {
      // For Arrays, append them individually to allow for passing
      // multiple values to PHP with the form data.
      value.forEach(valueItem => form.append(key + "[]", valueItem.toString()));
    } else {
      form.append(key, value.toString());
    }
  }
  return form;
}

type UpdateResponse = {
  success: boolean;
};

const updateFn =
  <SubmissionType extends CrudSubmission>(url: string, fetchAll: FetchAll) =>
  async (submission: SubmissionType, batchMode?: BatchMode) => {
    if (isDevMode()) return true;
    const form = recordToFormData(submission);
    try {
      const response = await axios.post(url, form);
      const { success } = response.data as UpdateResponse;
      if (success && !batchMode) {
        await fetchAll(); // reset cache
      }
      return success;
    } catch (error) {
      return false;
    }
  };

const fetchAllFn =
  <T>(url: string, defaultResponse: T) =>
  async (useCache?: 'useCache') => {
    if (useCache) {
      const cachedResponse = sessionStorage.getItem(url);
      if (cachedResponse) {
        return JSON.parse(cachedResponse) as T;
      }
    }
    if (isDevMode()) return defaultResponse;
    const response = await axios.get(url);
    const retVal = response.data as T;
    sessionStorage.setItem(url, JSON.stringify(retVal));
    return retVal;
  };

const fetchOneFn =
  <ResponseType extends { id: IDType }, IDType>(
    url: string,
    fetchAll: () => Promise<ResponseType[]>,
  ) =>
  async (id: IDType, useCache?: 'useCache') => {
    let cachedData: ResponseType[] | null = null;
    if (useCache) {
      const cachedResponse = sessionStorage.getItem(url);
      if (cachedResponse) {
        cachedData = JSON.parse(cachedResponse);
      }
    }
    let data = cachedData || (await fetchAll());
    return data.find(x => x.id == id) as ResponseType | undefined;
  };

type CreateResponse = {
  newID: number;
};

// TODO: update prettier to use new typescript otherwise created error
// type FetchAll<T> = ReturnType<typeof fetchAllFn<T>>;
type FetchAll = ReturnType<typeof fetchAllFn>;

const createFn =
  <SubmissionType extends CrudSubmission>(url: string, fetchAll: FetchAll) =>
  async (submission: Omit<SubmissionType, 'id'>, batchMode?: BatchMode) => {
    if (isDevMode()) return 1;
    const form = recordToFormData(submission);
    try {
      const response = await axios.post(url, form);
      const { newID } = response.data as CreateResponse;
      if (newID && !batchMode) {
        await fetchAll(); // reset cache
      }
      return newID;
    } catch (error) {}
  };

type DeleteResponse = {
  success: boolean;
};

const deleteFn =
  <IDType extends Stringable>(url: string, fetchAll: FetchAll) =>
  async (id: IDType, batchMode?: BatchMode) => {
    if (isDevMode()) return true;
    const form = recordToFormData({ id });
    try {
      const response = await axios.post(url, form);
      const { success } = response.data as DeleteResponse;
      if (success && !batchMode) {
        await fetchAll(); // reset cache
      }
      return success;
    } catch (error) {
      return false;
    }
  };

export const makeAPIFn = {
  fetchAllFn,
  fetchOneFn,
  createFn,
  updateFn,
  deleteFn,
};

type TableBackend<T> = {
  fetchURL: string;
  testData: T[];
  createURL: string;
  updateURL: string;
  deleteURL: string;
};

export function makeTableAPI<
  T extends { id: IDType },
  IDType extends Stringable = number,
>(backend: TableBackend<T>) {
  const fetchAll = fetchAllFn(backend.fetchURL, backend.testData);
  return {
    fetchAll,
    fetchOne: fetchOneFn<T, IDType>(backend.fetchURL, fetchAll),
    create: createFn<T & CrudSubmission>(backend.createURL, fetchAll),
    update: updateFn<T & CrudSubmission>(backend.updateURL, fetchAll),
    delete: deleteFn<IDType>(backend.deleteURL, fetchAll),
  };
}

export function makeTableAPI2<
  T extends { id: IDType },
  IDType extends Stringable = number,
>(tableName: string, testData: T[]) {
  return makeTableAPI<T, IDType>({
    fetchURL: `/api/strapDB/${tableName}s.php`,
    createURL: `/api/strapDB/${tableName}Create.php`,
    updateURL: `/api/strapDB/${tableName}Update.php`,
    deleteURL: `/api/strapDB/${tableName}Delete.php`,
    testData,
  });
}
