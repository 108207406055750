import { Button, Icon } from '@mui/material';
import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import strapDB from '../api/strapDB';
import * as styles from './adminComponents.module.scss';

type ViewerPageAdminToolbarProps = {
  id: number;
  table: keyof Omit<typeof strapDB, 'book'>; // book can't be modified
  onDeleted?: () => void;
};

export function ViewerPageAdminToolbar(props: ViewerPageAdminToolbarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  function onClickEdit() {
    const url = (() => {
      if (location.pathname.endsWith(`/${props.id}`)) {
        // url ends with /:id
        return `${location.pathname}/edit`;
      }
      // otherwise add a /:id/ (for article group viewer)
      return `${location.pathname}/${props.id}/edit`;
    })();
    navigate(url);
  }

  function onClickDelete() {
    if (confirm('Are you sure you would like to delete this page?')) {
      strapDB[props.table].delete(props.id);
      if (props.onDeleted) props.onDeleted();
      const basePath = '/' + location.pathname.split('/')[1];
      navigate(basePath);
    }
  }

  return (
    <div className={styles.viewerPageToolbar}>
      <Button
        onClick={onClickEdit}
        variant="contained"
        disableRipple
        startIcon={<Icon>edit</Icon>}
      >
        Edit
      </Button>
      <Button
        onClick={onClickDelete}
        variant="text"
        disableRipple
        startIcon={<Icon>delete</Icon>}
      >
        Delete
      </Button>
    </div>
  );
}

type AdminBigButtonProps = {
  onClick: () => void;
  label: string
};

export function AdminBigButton(props: AdminBigButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      className={styles.adminBigButton}
      color="primary"
      size="large"
      variant="contained"
      startIcon={<Icon>add</Icon>}
      disableRipple
    >
      {props.label}
    </Button>
  );
}
