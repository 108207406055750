import { mockDataOrDefault } from "../utils/functions";

export const defaultGetCreditsResp = mockDataOrDefault<CreditJson[]>(
  [],
  [
    { id: 1, name: "TST_001" },
    { id: 2, name: "TST_002" },
    { id: 3, name: "TST_003" },
    { id: 4, name: "TST_004" },
    { id: 5, name: "TST_005" },
  ]
);
