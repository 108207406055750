.articleHeader {
  & > .publishedOn,
  & > .byline {
    margin-bottom: 0.2em;
  }
  & > .byline {
    // HIDE BYLINE FOR NOW
    display: none;
  }
  & > .articleType {
    margin-top: 8px;
  }
  & > hr {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
}

.articleViewer {
  :global {
    img,
    video,
    .articleVideo--iframeContainer {
      margin: 1rem 0 0;
      max-width: 100%;
    }

    .articleVideo--iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
    }

    video[src$='.mp3'] {
      height: 50px;
      width: 100%;
    }

    @media (min-width: 576px) {
      img,
      video,
      .articleVideo--iframeContainer {
        max-width: min(90%, 80vw, 600px);
      }
    }
  }
}
