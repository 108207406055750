import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { LabelledCheckbox } from '../utils/miscComponents';
import * as styles from './shopPage.module.scss';
import { useSearchParams } from 'react-router-dom';
import strapDB from '../api/strapDB';
import { FILTER_KEY } from './utils';

type ShopFilterProps = {
  /** @param enabledFilterIDs which filters are now ticked */
  onClose: (enabledFilterIDs: number[]) => void;

  isOpen: boolean;

  /**
   * If defined, it will be controlled by this, otherwise from url
   * search params. onClose should become a setter for this.
   */
  filters?: number[];
};

type CheckboxListDefaultOptions = {
  title: string;
  filters: ProductFiltersJson[];
};

/**
 * Pre-ordained list of known filters collated into logical groups.
 * TODO: move to db?
 */
const CHECKBOX_LISTS: CheckboxListDefaultOptions[] = [
  {
    title: 'Category',
    filters: [
      { id: 1, name: 'books' },
      { id: 2, name: 'clothing' },
      { id: 3, name: 'jewelry' },
      { id: 4, name: 'accessories' },
      { id: 5, name: 'bags' },
      { id: 6, name: 'home' },
    ],
  },
  {
    title: 'Person',
    filters: [
      { id: 8, name: 'mens' },
      { id: 9, name: 'ladies' },
      { id: 10, name: 'childrens' },
    ],
  },
  {
    title: 'Material',
    filters: [
      { id: 11, name: 'silver' },
      { id: 12, name: 'ceramic' },
      { id: 13, name: 'fabric' },
      { id: 14, name: 'gold' },
      { id: 15, name: 'wood' },
      { id: 16, name: 'glass' },
      { id: 10, name: 'silk' },
    ],
  },
];

export const DEFAULT_FILTERS_CHECKED_STATES: FiltersCheckedStates =
  Object.fromEntries(
    CHECKBOX_LISTS.flatMap(checkboxList =>
      checkboxList.filters.map(filter => [filter.id, false]),
    ),
  );

/** { FilterID: isChecked, ... } */
export type FiltersCheckedStates = Record<number, boolean>;

export default function ShopFilterDialog(props: ShopFilterProps) {
  const [filtersCheckedStates, setFiltersCheckedStates] =
    useState<FiltersCheckedStates>(DEFAULT_FILTERS_CHECKED_STATES);

  const [searchParams, setSearchParams] = useSearchParams();

  function loadFromSearchParams() {
    const activeFilterIDStrings = searchParams.getAll(FILTER_KEY);
    const newValue = {
      ...Object.fromEntries(
        Object.keys(filtersCheckedStates).map(filterID => [filterID, false]),
      ),
      ...Object.fromEntries(
        activeFilterIDStrings.map(filterIDString => [+filterIDString, true]),
      ),
    };
    setFiltersCheckedStates(newValue);
    return newValue; // return so it can be used elsewhere
  }

  function getOldFiltersCheckedStates() {
    return props.filters !== undefined
      ? filterIDsToCheckedStates(props.filters)
      : loadFromSearchParams();
  }

  useEffect(() => {
    // ensure the table is fetched ready to close
    strapDB.productFilters.fetchAll('useCache');
  }, []);

  useEffect(() => {
    if (props.filters !== undefined) {
      setFiltersCheckedStates(filterIDsToCheckedStates(props.filters));
    }
  }, [props.filters]);

  function filterIDsToCheckedStates(filterIDs: number[]) {
    return {
      ...DEFAULT_FILTERS_CHECKED_STATES,
      ...Object.fromEntries(filterIDs.map(filterID => [filterID, true])),
    };
  }

  function saveToSearchParams() {
    searchParams.delete(FILTER_KEY);
    Object.entries(filtersCheckedStates).forEach(([filterIDStr, isChecked]) => {
      if (isChecked) searchParams.append(FILTER_KEY, filterIDStr);
    });
    setSearchParams(searchParams);
  }

  // Reload data on dialog open from search params
  useEffect(
    () =>
      void (
        props.isOpen &&
        props.filters === undefined &&
        loadFromSearchParams()
      ),
    [props.isOpen],
  );

  /** @returns a list of just those filters that are ticked. */
  function getValuesAsTickedList(values?: FiltersCheckedStates) {
    return Object.entries(values ?? filtersCheckedStates)
      .filter(([_filterIDStr, isChecked]) => isChecked)
      .map(([filterIDStr]) => +filterIDStr); // string from Object.entries
  }

  function onCloseDialog() {
    const oldValues = getOldFiltersCheckedStates();
    props.onClose(getValuesAsTickedList(oldValues));
  }

  function onClickCancel() {
    const oldValues = getOldFiltersCheckedStates();
    props.onClose(getValuesAsTickedList(oldValues));
  }

  function onClickApply() {
    if (props.filters === undefined) {
      // save to search params if not using controlled state
      saveToSearchParams();
    }
    props.onClose(getValuesAsTickedList());
  }

  return (
    <Dialog fullWidth onClose={onCloseDialog} open={props.isOpen}>
      <DialogContent>
        <form>
          <h4>Product Filters</h4>
          <div className={styles.checkboxListWrapper}>
            {CHECKBOX_LISTS.map((checkboxListProps, i) => (
              <CheckboxList
                key={i}
                {...checkboxListProps}
                checkedStates={filtersCheckedStates}
                setCheckedStates={setFiltersCheckedStates}
              />
            ))}
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickApply} variant="contained" disableRipple>
          Apply
        </Button>
        <Button onClick={onClickCancel} disableRipple>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type CheckboxListProps = CheckboxListDefaultOptions & {
  checkedStates: FiltersCheckedStates;
  setCheckedStates: (value: FiltersCheckedStates) => void;
};

/** If more than this many items, add a 'show more' button. */
const MAX_ITEMS_BEFORE_SHOW_MORE = 4;

function CheckboxList(props: CheckboxListProps) {
  const [isShowingMore, setIsShowingMore] = useState(false);

  function onClickReset() {
    props.setCheckedStates({
      ...props.checkedStates,
      ...Object.fromEntries(props.filters.map(filter => [filter.id, false])),
    });
  }

  /** called when the 'any' checkbox is checked */
  function onChangeAnyCheckBox() {
    onClickReset();
  }

  function onChangeCheckBox(filterID: number) {
    props.setCheckedStates({
      ...props.checkedStates,
      [filterID]: !props.checkedStates[filterID],
    });
  }

  function onClickShowMore() {
    setIsShowingMore(true);
  }

  const isSomethingChecked = Object.entries(props.checkedStates).some(
    ([filterID, isChecked]) =>
      // object.entries returns keys as strings, so we need to convert
      // back to number
      isChecked && props.filters.map(x => x.id).includes(+filterID),
  );
  const hasManyItems = props.filters.length > MAX_ITEMS_BEFORE_SHOW_MORE;
  const canShowMore = hasManyItems && !isShowingMore;

  // TODO: search DB for products with matching number of the filters names
  const itemsToShow = isShowingMore
    ? props.filters
    : props.filters.slice(0, MAX_ITEMS_BEFORE_SHOW_MORE);

  function getValueValidated(filterID: number) {
    const value = props.checkedStates[filterID];
    if (value === undefined)
      throw new Error(`Undefined value for itemName '${filterID}'`);
    return value;
  }

  return (
    <div className={styles.checkboxList}>
      <h5>
        {props.title}
        {isSomethingChecked && (
          <Button
            className={styles.filterReset}
            disableRipple={true}
            onClick={onClickReset}
            size="small"
          >
            Reset
          </Button>
        )}
      </h5>
      <div>
        <LabelledCheckbox
          label="(any)"
          onChange={onChangeAnyCheckBox}
          value={!isSomethingChecked}
        />
        {itemsToShow.map(filter => (
          <LabelledCheckbox
            label={filter.name}
            key={filter.id}
            onChange={() => onChangeCheckBox(filter.id)}
            value={getValueValidated(filter.id)}
          />
        ))}
        {
          // An additional indicator there is more, in the same flow as
          // the item names for additional usability.
        }
        {canShowMore && <span onClick={onClickShowMore}>...</span>}
      </div>
      {canShowMore && (
        <Button disableRipple={true} onClick={onClickShowMore} size="small">
          Show more
        </Button>
      )}
    </div>
  );
}
