import { Button, Checkbox, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { verifyViewingPageAsAdminOrLogin } from '../admin/adminUtils';
import strapDB from '../api/strapDB';
import BasicPage from '../utils/basicPage';
import { tryCatch } from '../utils/functions';
import ImageGalleryEditor from '../utils/imageGalleryEditor';
import { useSnackbar } from '../utils/snackbar';
import * as viewerStyles from './productViewerPage.module.scss';
import ShopFilterDialog, {
  DEFAULT_FILTERS_CHECKED_STATES,
  FiltersCheckedStates,
} from './shopFilterDialog';

export default function ProductEditPage() {
  verifyViewingPageAsAdminOrLogin();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [imageLinks, setImageLinks] = useState<string[]>([]);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<number[]>([]);

  const params = useParams();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  let productID = parseInt(params.productID || '') || undefined;

  useEffect(() => {
    if (productID) {
      loadProductData(productID);
    }
  }, [productID]);

  async function loadProductData(productID: number) {
    const productData = await strapDB.product.fetchOne(productID, 'useCache');
    if (!productData) {
      snackbar.error(`Invalid product ID: ${productID}`);
      navigate('/shop');
      return;
    }
    setTitle(productData.title);
    setDescription(productData.description ?? '');
    setPrice(productData.price / 100);
    setDeliveryCost(productData.deliveryCost / 100);
    setImageLinks(tryCatch(() => JSON.parse(productData.imageLinks)) ?? []);
    setIsOutOfStock(productData.isOutOfStock);
    setFilters(productData.filters);
  }

  function onClickDiscard() {
    if (
      confirm(
        `Are you sure you want to discard${
          productID ? ' changes to' : ''
        } the product?`,
      )
    ) {
      navigate(productID ? `/shop/product/${productID}` : '/shop');
    }
  }

  async function onClickSave() {
    const submission = {
      title,
      description,
      price: Math.floor(price * 100), // to pence
      deliveryCost: Math.floor(deliveryCost * 100),
      imageLinks: JSON.stringify(imageLinks),
      isOutOfStock,
      filters,
    };
    console.debug(
      'onClickSave() submission:',
      submission,
      'productID:',
      productID,
    );
    const saveSuccess = await (productID !== undefined
      ? saveOverExisting({ ...submission, id: productID })
      : saveAsNew(submission));
    showSaveNotification(saveSuccess, productID);
  }

  function saveOverExisting(submission: ProductJson) {
    return strapDB.product.update(submission);
  }

  async function saveAsNew(submission: Omit<ProductJson, 'id'>) {
    const newProductID = await strapDB.product.create(submission);
    productID = newProductID; // save in editor state
    if (newProductID) {
      navigate(`/shop/product/${newProductID}/edit`, { replace: true });
      return true;
    }
    return false;
  }

  function showSaveNotification(saveSuccess: boolean, productID?: number) {
    if (saveSuccess) {
      if (!productID) throw new Error('invalid productID for successful save');
      snackbar.success('Saved product', {
        action: (
          <Button
            className="saveSuccessAction"
            variant="text"
            color="secondary"
            onClick={() => navigate(`/shop/product/${productID}`)}
          >
            View page
          </Button>
        ),
      });
    } else {
      snackbar.error('Failed to save product');
    }
  }

  function onClickCustomizeFilters() {
    console.debug('onClickCustomizeFilters()');
    setIsFiltersOpen(true);
  }

  function onCloseFiltersDialog(enabledFilterIDs: number[]) {
    setIsFiltersOpen(false);
    setFilters(enabledFilterIDs);
  }

  const canSave = title && price > 0 && imageLinks.length != 0;

  return (
    <BasicPage title="Product Edit">
      <h2 className={viewerStyles.title}>
        <input
          className="w-100"
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Title"
        />
      </h2>
      <Row>
        <Col md={8} style={{ height: '400px', overflowY: 'auto' }}>
          <ImageGalleryEditor
            value={imageLinks}
            onChange={value => setImageLinks(value)}
          />
        </Col>
        <Col md={4}>
          <p>
            <textarea
              className="w-100"
              placeholder="Description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              rows={8}
            />
          </p>
          <p>
            Price: &pound;
            <input
              type="number"
              className={viewerStyles.editPrice}
              value={price}
              onChange={e => setPrice(+(+e.target.value || 0).toFixed(2))}
              min={0}
            />
            <br />
            Delivery: &pound;
            <input
              type="number"
              className={viewerStyles.editPrice}
              value={deliveryCost}
              onChange={e =>
                setDeliveryCost(+(+e.target.value || 0).toFixed(2))
              }
              min={0}
            />
          </p>
          <p className="d-flex align-items-center">
            Out Of Stock:&nbsp;
            <Checkbox
              className="p-0"
              disableRipple
              checked={isOutOfStock}
              onChange={e => setIsOutOfStock(e.target.checked)}
            />
          </p>
          <Button
            disableRipple={true}
            variant="outlined"
            onClick={onClickCustomizeFilters}
          >
            Customize Filters
          </Button>
          <ShopFilterDialog
            filters={filters}
            isOpen={isFiltersOpen}
            onClose={onCloseFiltersDialog}
          />
        </Col>
      </Row>
      <Stack className="mt-3" direction="row" gap={1}>
        <Button
          onClick={onClickSave}
          disabled={!canSave}
          variant="contained"
          disableRipple
        >
          Save
        </Button>
        <Button onClick={onClickDiscard} variant="text" disableRipple>
          Discard {productID ? 'changes' : 'product'}
        </Button>
      </Stack>
    </BasicPage>
  );
}
