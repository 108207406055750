.itemList {
  display: grid;
  grid-template-columns: repeat(8, max-content);
  max-height: 80vh;
  max-width: fit-content;
  overflow-x: auto;
  border: 1px solid #ccc;
  border-bottom: none;
  & > :nth-child(1) > * {
    font-weight: 600;
    position: sticky;
    background: white;
    z-index: 10;
    align-self: start;
    top: 0;
  }
  & > :nth-child(even) > * {
    background: #eee;
  }
  & a,button {
    justify-self: start;
    padding: 0;
    &:hover {
    color: rgb(219, 163, 43);
    }
  }
}

.row {
  display: contents;
  & > * {
    border-bottom: 1px solid #ccc;
  }
  $colGap: 1rem;
  & > * {
    padding: 0.2rem calc($colGap / 2);
  }
  & > :nth-child(1) {
    padding-left: $colGap;
  }
}
