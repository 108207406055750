@use './articleViewer.module.scss';
@use './articleViewerPage.module.scss';

.articleEditorPage {
  visibility: visible;
}

.articleHeader {
  & h2 > input {
    // headline label edit box full width
    width: 100%;
  }
  & > .publishedOn {
    // Reinstate published on for editor only, for grouping articles.
    display: block;
  }
}

.saveSuccessAction {
  color: white !important;
}
