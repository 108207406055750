import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Chip } from '@mui/material';
import TagSelect from './sectionSelect';
import SearchBox from './searchBox';


export type QueryFilters = {
  search?: string;
  section?: string;
};

type QueryBarProps = {
  onFiltersChanged: (value: QueryFilters) => void;
  filters: QueryFilters;
};

export default function QueryBar(props: QueryBarProps) {
  const updateFilters = (newOptions: Partial<QueryFilters>) => {
    // Have to apply to variable as react state variable not updated
    // immediately after setting.
    const newFilters = { ...props.filters, ...newOptions };
    Object.entries(newFilters)
      .filter(([k, v]) => !v)
      .forEach(([k]) => {
        delete newFilters[k as keyof QueryFilters];
      });
    props.onFiltersChanged(newFilters);
  };

  const onSubmitSearch = (search: string) => {
    updateFilters({ search });
  };

  const onSelectTag = (tag: SectionJson) => {
      updateFilters({ section: tag.name });
  };

  const onDeleteChip = (chip: QueryChip) => {
    updateFilters({ [chip.type]: undefined });
  };

  return (
    <div>
      <div className="mb-2 d-flex" style={{ columnGap: '2rem' }}>
        <SearchBox onSubmit={onSubmitSearch} />
        {/* <TagSelect onSelect={onSelectTag} /> */}
      </div>

      <div className="my-2 d-flex" style={{ columnGap: '1ch' }}>
        {asChips(props.filters).map((i, x) => (
          <Chip key={x} label={i.label} onDelete={() => onDeleteChip(i)} />
        ))}
      </div>
    </div>
  );
}

type QueryChip = { label: string; type: keyof QueryFilters };

function asChips(query: QueryFilters) {
  return [
    query.search ? { label: query.search, type: 'search' } : 0,
    query.section ? { label: `In: ${query.section}`, type: 'section' } : 0,
  ].filter(x => x != 0) as QueryChip[];
}
