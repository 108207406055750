import React from 'react';
import * as styles from './mainPage.module.scss';


type SectionCaurouselProps = {
  section: number;
  sects: SectionJson[];
  onSelect: (value: SectionJson) => void;
};
export default function SectionGrid(props: SectionCaurouselProps) {
  return (
        <div className={styles.section}>
          {props.sects.map(sect => (
            <button className={styles.sectionInner} key={sect.id} onClick={() => props.onSelect(sect)}>
              {sect.name}
            </button>
          ))}
        </div>
  );
}
