.viewerPageToolbar {
  margin-bottom: 0.7em;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.adminBigButton {
  width: 100%;
  height: 80px;
  margin-bottom: 1em !important;
}
