.newsSection {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: center;
  color: black;
  gap: 3em;
  padding: 5px;
  border-radius: 13px;
}

.header1 {
  font-weight: lighter;
  font-size: 1.75em;
  text-align: center;
  color: black;
  padding-bottom: 0.5em;
}

.gridButton {
  $buttonColor: #4d7e45;
  background-color: $buttonColor;
  color: white;
  &:visited {
    color: white;
  }
  &:hover {
    background-color: lighten($buttonColor, 8%);
    color: darken(white, 13%);
  }
  transition: background-color 0.2s, color 0.2s;

  font-size: 1.2em;
  text-decoration: none;
  display: flex;
  padding: 10px;
  width: 220px;
  height: 100px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .newsSection {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
}

@media screen and (max-width: 600px) {
  .newsSection {
    grid-template-columns: auto;
  }
}
