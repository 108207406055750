import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import './photoStack.sass';

type PhotoStackLayerElement = ReactElement<PhotoStackLayerProps>;

type PhotoStackProps = {
  children: PhotoStackLayerElement | PhotoStackLayerElement[];
};

/** A friendly stack of images. */
export default function PhotoStack(props: PhotoStackProps) {
  const children =
    props.children instanceof Array ? props.children : [props.children];
  const [index, setIndex] = useState(0);
  const [rotations] = useState(getLayerRotations(children.length));
  const order = iota(children.length)
    .map(i => i + index)
    .map(j => (j >= children.length ? j - children.length : j))
    .reverse();

  return (
    <div className="photoStack">
      {order.map((i, j) => (
        <PhotoStackLayerImpl
          key={i}
          orderNo={order.length - 1 - j}
          layerCount={order.length}
          {...children[i]!.props}
          rotation={rotations[i] ?? 0}
          onClick={() => {
            setIndex(index < children.length - 1 ? index + 1 : 0);
          }}
        />
      ))}
    </div>
  );
}

type PhotoStackLayerProps = {
  wide?: boolean;
  src: string;
  caption?: string;
};

export function PhotoStackLayer(props: PhotoStackLayerProps) {
  return <> </>;
}

type PhotoStackLayerImplProps = PhotoStackLayerProps & {
  rotation?: number;
  orderNo: number;
  layerCount: number;
  onClick?: () => void;
};

function PhotoStackLayerImpl(props: PhotoStackLayerImplProps) {
  return (
    <div className="photoStack--layerRoot" onClick={props.onClick}>
      <div
        className={classNames('photoStack--layer', {
          'photoStack--layerWide': props.wide,
          'photoStack--layerTop': props.orderNo == 0,
        })}
        style={{
          transform: `rotate(${props.rotation!}deg)`,
          // @ts-ignore
          '--orderPercent': (props.orderNo + 1) / Math.max(props.layerCount, 6),
        }}
      >
        <img src={props.src}></img>
        <span>{props.caption}</span>
      </div>
    </div>
  );
}

function getLayerRotations(photoCount: number) {
  const firstMagnitude = 2;
  const magnitude = 8;
  const difference = 3;

  const getRandom = (magnitude: number) =>
    mapRange(Math.random(), 0, 1, -magnitude, magnitude);
  const first = getRandom(firstMagnitude);
  const retVal: number[] = [first];
  for (let i = 1; i < photoCount; i++) {
    const prev = retVal[i - 1]!;
    let next = prev;
    do next = getRandom(magnitude);
    while (
      Math.abs(prev - next) < difference &&
      Math.sign(prev) == Math.sign(next)
    );
    retVal.push(next);
  }
  return retVal;
}

function mapRange(a: number, b: number, c: number, d: number, e: number) {
  return d + (e - d) * ((a - b) / (c - b));
}

function iota(n: number) {
  const ret = [];
  for (let i = 0; i < n; i++) {
    ret.push(i);
  }
  return ret;
}
