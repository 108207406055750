import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Modal,
  Skeleton,
  Typography,
} from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { FormEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CartExpanded, useCart, useCartTotal } from '../api/cartAPI';
import {
  confirmStripePayment,
  createPaymentIntent,
  submitPayment,
} from '../api/shopAPI';
import BasicPage from '../utils/basicPage';
import * as styles from './paymentPage.module.scss';
import CheckoutStepper from './checkoutStepper';
import { AddressInput, LabelledCheckbox } from '../utils/miscComponents';
import { formatCurrency } from '../utils/stringUtils';
import { useCheckoutForm } from './shippingPage';
import { useSnackbar } from '../utils/snackbar';
import { useEffectOnce } from 'usehooks-ts';
import { isDevMode } from '../utils/functions';

export default function PaymentPage() {
  const [hideAddr2, setHideAddr2] = useState(true);
  const [billingAddr, setBillingAddr] = useState<Address | undefined>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paySucceed, setPaySucceed] = useState(false);
  const [stripeError, setStripeError] = useState('');
  const [checkoutForm, setCheckoutForm] = useCheckoutForm();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const cart = useCart();

  const [canUseShippingAddr, setCanUseShippingAddr] = useState(false);

  if (cart.items.length == 0) {
    snackbar.error('Invalid shopping cart');
    useEffect(() => void navigate('/'), []);
    return <></>;
  }

  if (!checkoutForm) {
    // allowed to still show page if success
    snackbar.error('Invalid or missing checkout form');
    useEffect(() => void navigate('/shop/shipping'), []);
    return <></>;
  }

  // Clear billing addr on check same as shipping
  useEffect(() => {
    if (hideAddr2) setBillingAddr(undefined);
  }, [hideAddr2]);

  // Only use checkout form if shipping addr was defined.
  useEffect(() => {
    if (checkoutForm) {
      const val = !!checkoutForm.shippingAddr;
      setCanUseShippingAddr(val);
      // Always show billing addr if can't use same as shipping.
      if (!val) {
        setHideAddr2(false);
      }
    }
  }, [checkoutForm]);

  // Create payment intent
  useEffectOnce(() => {
    if (checkoutForm) {
      createPaymentIntent(cart.items, checkoutForm.customerCollects).then(
        setClientSecret,
      );
    }
  });

  function onClickBack() {
    navigate('/shop/shipping');
  }

  async function onSubmit(e: FormEvent) {
    if (!checkoutForm) throw new Error('invalid checkout form on submit');
    e.preventDefault();
    if (!isDevMode() && (!stripe || !elements || !clientSecret)) {
      return;
    }

    // initial delay incase of immediate errors to prevent showing and
    // hiding the dialog immediately
    const procID = setTimeout(() => setIsProcessing(true), 100);

    // Store details in database in preparation for stripe callback.
    if (
      !(await submitPayment({ ...checkoutForm, clientSecret, billingAddr }))
    ) {
      alert('unusual error in submitting payment - please try again');
    }

    const realBillingAddr = billingAddr || checkoutForm.shippingAddr;
    if (!realBillingAddr)
      throw new Error('neither billing nor shipping addr defined!');
    const stripeError = await confirmStripePayment(
      elements!,
      stripe!,
      clientSecret,
      realBillingAddr,
    );
    if (stripeError) {
      setStripeError(stripeError);
    } else {
      // clear cart and checkout form to prevent double payment
      (() => {
        // non-async doo all at once instead of rerendering - less bugs
        setPaySucceed(true);
        localStorage.removeItem('cart');
        sessionStorage.removeItem('checkout'); // don't cause rerender
        // location.href = `/api/shopAPI/invoice.php?order=${clientSecret}`;
      })();
      return;
    }
    // otherwise make it so you can try again
    clearTimeout(procID);
    setIsProcessing(false);
  }

  return (
    <BasicPage title="Checkout">
      <CheckoutStepper currentStep={2} />
      <form onSubmit={onSubmit}>
        <Row>
          <Col lg={3} md={2} />
          <Col lg={6} md={8}>
            <h5 className="mt-0">Billing Address</h5>
            {canUseShippingAddr && (
              <LabelledCheckbox
                label="Same As Shipping Address"
                value={hideAddr2}
                onChange={setHideAddr2}
              />
            )}
            {!hideAddr2 && (
              <AddressInput
                prefix="billing"
                value={billingAddr}
                onChange={setBillingAddr}
              />
            )}
            <h5 className="mt-2">Card Details</h5>
            <CardInput clientSecret={clientSecret} error={stripeError} />
            <SummaryTable checkoutForm={checkoutForm} />
            <div className={styles.actions}>
              <Button disableRipple onClick={onClickBack}>
                Back
              </Button>
              <Button type="submit" variant="contained" disableRipple>
                Confirm
              </Button>
            </div>
          </Col>
        </Row>
      </form>
      <LoadingScreen isProcessing={isProcessing} paySucceed={paySucceed} />
    </BasicPage>
  );
}

/** Listing of prices to pay */
function SummaryTable({ checkoutForm }: { checkoutForm: CheckoutForm }) {
  const cart = useCart();
  const [productsTotal, setProductsTotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  useEffect(() => {
    if (!checkoutForm.customerCollects) {
      cart.getShippingTotal().then(setShippingCost);
    }
    cart.getProductsTotal().then(setProductsTotal);
  }, [cart]);
  const grandTotal = productsTotal + shippingCost;

  return (
    <div className={styles.summaryTable}>
      <span>Products Total:</span>
      <span>{formatCurrency(productsTotal)}</span>
      <span>Shipping Costs:</span>
      <span>{formatCurrency(shippingCost)}</span>
      <hr /> <hr />
      <span>Grand Total:</span>
      <span>{formatCurrency(grandTotal)}</span>
    </div>
  );
}

type CardInputProps = {
  /** empty string for not ready yet */
  clientSecret: string;
  /** error message, if any */
  error: string;
};

/** Card inputs or skeleton loader */
function CardInput(props: CardInputProps) {
  if (!props.clientSecret && 0) {
    return (
      <Skeleton
        className={styles.stripeCardInput}
        height={19}
        variant="rectangular"
      />
    );
  }

  return (
    <>
      <CardElement
        className={styles.stripeCardInput}
        options={{
          hidePostalCode: true,
          style: { base: { fontSize: '16px' } },
        }}
      />
      <Typography color="error">{props.error}</Typography>
    </>
  );
}

type LoadingScreenProps = {
  isProcessing: boolean;
  paySucceed: boolean;
};

function LoadingScreen({ isProcessing, paySucceed }: LoadingScreenProps) {
  const navigate = useNavigate();

  return (
    <Modal open={isProcessing}>
      <div className={styles.loadingScreen}>
        <div>
          {!paySucceed ? (
            <>
              <h4>Processing Payment</h4>
              <CircularProgress color="primary" />
              <span>Please do not reload the page</span>
            </>
          ) : (
            <>
              <h4>Payment Succeeded</h4>
              <span>Thanks for your order!</span>
              <span>
                Your receipt will be sent
                <br />
                by email.
              </span>
              <Button
                disableRipple
                variant="contained"
                onClick={() => navigate('/')}
              >
                Return to site
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
