import { Badge, Icon, IconButton, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../api/cartAPI';
import NavbarAdornment from '../utils/navbarAdornment';
import * as styles from './shopPage.module.scss';

type CartWidgetProps = {
  variant: 'sticky' | 'fixed';
};

export default function CartWidget(props: CartWidgetProps) {
  const navigate = useNavigate();
  const cart = useCart();
  const isSmall = useMediaQuery('(max-width: 767px)');

  function onClickExpand() {
    navigate('/shop/cart');
  }

  return (
    <NavbarAdornment
      variant={props.variant}
      position="pushedOut"
      onClick={onClickExpand}
    >
      <div className={styles.cartWidget}>
        {isSmall ? (
          <>
            <Badge badgeContent={cart.items.length} color="primary">
              <Icon fontSize="small">shopping_cart</Icon>
            </Badge>
          </>
        ) : (
          <>
            <Icon fontSize="small">shopping_cart</Icon>
            {cart.items.length}&nbsp;Item{cart.items.length == 1 ? '' : 's'}
            <IconButton size="small" disableRipple>
              <Icon>chevron_right</Icon>
            </IconButton>
          </>
        )}
      </div>
    </NavbarAdornment>
  );
}
