import strapDB from '../api/strapDB';
import { ShopSortType } from '../utils/enums';

/** Full suite of options for the display of items. */
export type ShopItemsView = {
  search: string;
  sort: ShopSortType;
  filters: ProductFiltersJson[];
};

/** Performs the actual sorting and searching of items */
export function applyProductsItemView(
  products: ProductJson[],
  view: ShopItemsView,
) {
  return (
    products
      // step one: search term
      .filter(
        product =>
          !view.search ||
          product.title.toLowerCase().includes(view.search.toLowerCase()),
      )
      // step two: match one or more filters
      .filter(
        product =>
          // if no filters selected, show everything
          !view.filters.length ||
          product.filters.some(productFilterId =>
            view.filters.map(x => x.id).includes(productFilterId),
          ),
      )
      // step three: sort by chosen sorting method
      .sort(
        (a, b) =>
          view.sort == ShopSortType.AToZ
            ? a.title.toLowerCase().localeCompare(b.title.toLowerCase())
            : view.sort == ShopSortType.DateAddedAscending
            ? a.id - b.id
            : view.sort == ShopSortType.DateAddedDescending
            ? b.id - a.id
            : view.sort == ShopSortType.PriceAscending
            ? a.price - b.price
            : view.sort == ShopSortType.PriceDescending
            ? b.price - a.price
            : 0, // 0 - don't care sorting order
      )
  );
}

export async function filterIDsToFilterJsons(filterIDs: number[]) {
  const allFilters = await strapDB.productFilters.fetchAll('useCache');
  return allFilters.filter(filter => filterIDs.includes(filter.id));
}

// search param keys
export const SEARCH_KEY = 'search';
export const FILTER_KEY = 'filter';
export const SORT_KEY = 'sort';
